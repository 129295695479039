import { TooltipProps } from 'recharts';
import { formatDate, getDateFormatBasedOnPeriod } from 'utils';

import { Box } from '../Box';
import { ChartTooltip } from '../ChartElements';
import { NumberFormat } from '../NumberFormat';
import { Typography } from '../Typography';

import { LineChartProps } from './LineChart';
import { getDataKeyIndex, LABEL_PREFIX, VALUE_PREFIX } from './util';

export type LineChartTooltipProps = TooltipProps<number, string> &
  Pick<LineChartProps, 'locale' | 'periodDisplayMode'> & {
    isMultiline: boolean;
    digit?: number | 'auto';
  };

export const LineChartTooltip = ({
  active,
  payload,
  label,
  locale,
  periodDisplayMode,
  isMultiline,
  digit,
}: LineChartTooltipProps) => {
  if (!active) {
    return null;
  }

  if (isMultiline) {
    const totalAmount =
      payload
        ?.map(({ value }) => value ?? 0)
        .reduce((prev, current) => prev + current, 0) ?? 0;

    const displayLabel = label
      ? formatDate(
          new Date(label),
          locale,
          getDateFormatBasedOnPeriod(periodDisplayMode)
        )
      : null;

    const renderAssetCategories = () =>
      payload
        ?.sort((a, b) => {
          const { dataKey: dataKeyA } = a;
          const { dataKey: dataKeyB } = b;
          if (typeof dataKeyA === 'string' && typeof dataKeyB === 'string') {
            return getDataKeyIndex(dataKeyB) - getDataKeyIndex(dataKeyA);
          }
          return 0;
        })
        .map(({ color, name, value, payload }, index) => {
          const dataKey = name?.replace(`${VALUE_PREFIX}_`, '');

          return value ? (
            <Box
              key={dataKey ?? index}
              flex={1}
              justifyContent="space-between"
              paddingTop={index === 0 ? 's4' : 's5'}
              gap="s4"
            >
              <Typography variant="bodySmall" color="primary">
                {payload[`${LABEL_PREFIX}_${dataKey}`]}
              </Typography>
              <NumberFormat
                value={value}
                textVariant="bodySmall"
                style={{ color }}
              />
            </Box>
          ) : null;
        });

    return (
      <ChartTooltip
        minWidth={202}
        header={
          <>
            <Typography variant="bodySmall" color="tertiary">
              {displayLabel}
            </Typography>
            <NumberFormat
              value={totalAmount}
              textVariant="bodySmall"
              textColor="tertiary"
            />
          </>
        }
      >
        {renderAssetCategories()}
      </ChartTooltip>
    );
  }

  if (!payload?.[0]?.payload) {
    return null;
  }

  const { value, date } = payload[0].payload;

  return (
    <ChartTooltip minWidth={150}>
      <Box gap="s3" flexDirection="column">
        <Typography variant="bodySmall" color="tertiary">
          {formatDate(
            new Date(date),
            locale,
            getDateFormatBasedOnPeriod(periodDisplayMode)
          )}
        </Typography>
        <NumberFormat
          value={value}
          textVariant="bodySmall"
          textColor="accentPrimary"
          digit={digit}
        />
      </Box>
    </ChartTooltip>
  );
};
