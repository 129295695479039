import { Membership, Organization } from '../types';

export type SearchParams = string | URLSearchParams;

export const ORGANIZATION_VIEW_ID = 'organization' as const;

export type OrganizationRouteParams = {
  membershipId?: Membership['id'] | null;
  organizationId?: Organization['id'] | null;
  params?: SearchParams;
  viewAsFamily?: boolean; // TODO : think about a better naming
};

export const route = (path: string) => (params?: SearchParams) => {
  const urlSearchParams = params ? new URLSearchParams(params) : undefined;
  return `${path}${
    urlSearchParams?.toString() ? `?${urlSearchParams.toString()}` : ''
  }`;
};

export const organizationRoute =
  (path: string) =>
  ({
    organizationId,
    membershipId,
    params,
    viewAsFamily,
  }: OrganizationRouteParams) => {
    if (!membershipId || !organizationId) {
      return route(`/users/me${path}`)(params);
    }

    let base = `/organizations/${organizationId}`;
    if (membershipId !== ORGANIZATION_VIEW_ID && !viewAsFamily) {
      base += `/memberships/${membershipId}`;
    }

    return route(`${base}${path}`)(params);
  };

export const API_ROUTES = {
  advisorWaitlist: {
    post: route('/advisor_waitlist'),
  },
  bankAccountTypes: {
    get: route('/bank_account_types'),
    byOrganization: {
      get: organizationRoute('/bank_account_types'),
    },
  },
  banks: {
    init: {
      post: route('/users/me/banks/init'),
    },
  },
  bankRequests: {
    post: organizationRoute('/bank_requests'),
  },
  companies: {
    get: route('/companies'),
    post: route('/companies'),
    byId: (id: string) => ({
      get: route(`/companies/${id}`),
      put: route(`/companies/${id}`),
      delete: route(`/companies/${id}`),
    }),
  },
  cashflow: {
    recurringPayments: {
      get: organizationRoute('/cashflow/recurring_payments'),
      byId: (id: string) => ({
        put: organizationRoute(`/cashflow/recurring_payments/${id}`),
      }),
      distribution: {
        get: organizationRoute('/cashflow/recurring_payments/distribution'),
      },
    },
    distribution: {
      get: organizationRoute('/cashflow/distribution'),
      byId: (id: number) => ({
        get: organizationRoute(`/cashflow/distribution/${id}`),
      }),
    },
    availableMoney: {
      get: organizationRoute('/cashflow/available_money'),
    },
    dateRange: {
      get: organizationRoute('/cashflow/daterange'),
    },
    configuration: {
      get: organizationRoute('/cashflow/configuration'),
      put: organizationRoute('/cashflow/configuration'),
    },
  },
  cryptoExchangeRequests: {
    post: organizationRoute('/crypto_exchange_requests'),
  },
  connect: {
    connections: {
      byId: (id: string) => ({
        get: route(`/connect/connections/${id}`),
        put: route(`/connect/connections/${id}`),
        synchronizations: {
          post: route(`/connect/connections/${id}/synchronizations`),
          byId: (synchronizationId: string) => ({
            get: route(
              `/connect/connections/${id}/synchronizations/${synchronizationId}`
            ),
            requires2FaCode: {
              get: route(
                `/connect/connections/${id}/synchronizations/${synchronizationId}/requires_2fa_code`
              ),
            },
            requiresExternalAuth: {
              get: route(
                `/connect/connections/${id}/synchronizations/${synchronizationId}/requires_external_auth`
              ),
            },
            set2FaCode: {
              post: route(
                `/connect/connections/${id}/synchronizations/${synchronizationId}/set_2fa_code`
              ),
            },
          }),
        },
      }),
    },
    providers: {
      byId: (correlationId: string) => ({
        get: route(`/connect/providers/${correlationId}`),
      }),
    },
  },
  crypto_accounts: {
    post: organizationRoute('/crypto_accounts'),
    byId: (id: string | number) => ({
      put: organizationRoute(`/crypto_accounts/${id}`),
    }),
    checkAddress: {
      get: organizationRoute('/crypto_accounts/check_address'),
    },
  },
  crypto: {
    chains: {
      get: route('/crypto/chains'),
    },
  },
  cryptos: {
    byId: (id: number) => ({
      put: organizationRoute(`/cryptos/${id}`),
      delete: organizationRoute(`/cryptos/${id}`),
    }),
    post: organizationRoute('/cryptos'),
  },
  transactionCategories: {
    get: organizationRoute('/transaction_categories'),
    post: organizationRoute('/transaction_categories'),
    byId: (id: number) => ({
      put: organizationRoute(`/transaction_categories/${id}`),
      delete: organizationRoute(`/transaction_categories/${id}`),
      target: {
        put: organizationRoute(`/transaction_categories/${id}/target`),
      },
    }),
  },
  family: {
    members: {
      post: route('/users/me/family/members'),
      byId: (id: string) => ({
        put: route(`/users/${id}`),
        delete: route(`/users/me/family/members/${id}`),
      }),
    },
  },
  flanks: {
    reconnection: {
      post: route('/users/me/flanks/reconnection'),
    },
    resetToken: {
      get: route('/users/me/flanks/reset_tokens'),
    },
  },
  historicalData: {
    delete: route('/users/me/delete_historical_data'),
  },
  holdingsAccounts: {
    byId: (id: string) => ({
      put: organizationRoute(`/holdings_accounts/${id}`),
      delete: organizationRoute(`/holdings_accounts/${id}`),
      ownership: {
        put: organizationRoute(`/holdings_accounts/${id}/ownership`),
      },
      get: organizationRoute(`/holdings_accounts/${id}`),
    }),
    import: {
      post: route('/users/me/holdings_accounts/import'),
      byId: (id: string) => ({
        post: organizationRoute(`/holdings_accounts/${id}/import`),
      }),
    },
    get: organizationRoute('/holdings_accounts'),
    post: organizationRoute('/holdings_accounts'),
  },
  holdingsAccountContracts: {
    list: {
      get: route('/holdings_account_contracts/list'),
    },
    finary: {
      get: route('/holdings_account_contracts/finary'),
    },
    byId: (id: string) => ({
      put: organizationRoute(`/holdings_account_contracts/${id}`),
    }),
  },
  invest: {
    me: {
      get: route('/invest/v2/me'),
    },
    assetPrices: {
      get: route('/invest/v2/asset_prices'),
    },
    assets: {
      get: route('/invest/v2/assets'),
    },
    configuration: {
      get: route('/invest/v2/config'),
    },
    popularSavingsPlans: {
      get: route('/invest/v2/popular_savings_plans'),
    },
    directTransactions: {
      availableTargetAssets: {
        get: route('/invest/v2/direct_transactions/available_target_assets'),
      },
    },
    ledgers: {
      byId: (id: string) => ({
        get: route(`/invest/v2/ledgers/${id}`),
        accountingReport: {
          get: route(`/invest/v2/ledgers/${id}/accounting_report`),
        },
        depositAccount: {
          get: route(`/invest/v2/ledgers/${id}/deposit_account`),
          create: {
            get: route(`/invest/v2/ledgers/${id}/deposit_account/create`),
          },
        },
        directTransactions: {
          post: route(`/invest/v2/ledgers/${id}/direct_transactions`),
          byId: (transactionId: string) => ({
            status: {
              get: route(
                `/invest/v2/ledgers/${id}/direct_transactions/${transactionId}/status`
              ),
            },
          }),
        },
        export: {
          cryptoTransactions: {
            get: route(`/invest/v2/ledgers/${id}/export/crypto_transactions`),
          },
        },
        activity: {
          past: {
            get: route(`/invest/v2/ledgers/${id}/activity/past`),
          },
        },
        creditCardDeposits: {
          estimateFee: {
            post: route(
              `/invest/v2/ledgers/${id}/credit_card_deposits/estimate_fee`
            ),
          },
          stripePaymentMethods: {
            get: route(
              `/invest/v2/ledgers/${id}/credit_card_deposits/stripe_payment_methods`
            ),
          },
          stripeSetupIntents: {
            post: route(
              `/invest/v2/ledgers/${id}/credit_card_deposits/stripe_setup_intents`
            ),
          },
          stripePaymentIntents: {
            post: route(
              `/invest/v2/ledgers/${id}/credit_card_deposits/stripe_payment_intents`
            ),
          },
          stripeDetachPaymentMethod: {
            post: route(
              `/invest/v2/ledgers/${id}/credit_card_deposits/stripe_detach_payment_method`
            ),
          },
        },
        payments: {
          pending: {
            get: route(`/invest/v2/ledgers/${id}/payments/pending`),
          },
        },
        savingsPlans: {
          byId: (savingsPlanId: string) => ({
            delete: route(
              `/invest/v2/ledgers/${id}/savings_plans/${savingsPlanId}`
            ),
            pause: {
              put: route(
                `/invest/v2/ledgers/${id}/savings_plans/${savingsPlanId}/pause`
              ),
            },
            resume: {
              put: route(
                `/invest/v2/ledgers/${id}/savings_plans/${savingsPlanId}/resume`
              ),
            },
            put: route(
              `/invest/v2/ledgers/${id}/savings_plans/${savingsPlanId}`
            ),
          }),
          post: route(`/invest/v2/ledgers/${id}/savings_plans`),
        },
      }),
      post: route('/invest/v2/ledgers'),
    },
    waltio: {
      link: {
        get: route('/invest/v2/waltio/link'),
      },
    },
  },
  kycApplications: {
    post: route('/users/me/kyc_applications'),
  },
  kycInfos: {
    get: route('/users/me/kyc_infos'),
  },
  institution_connections: {
    byId: (id: string) => ({
      delete: organizationRoute(`/institution_connections/${id}`),
    }),
    details: {
      get: route('/users/me/institution_connections/details'),
    },
  },
  institution_providers: {
    byId: (id: string) => ({
      get: organizationRoute(`/institution_providers/${id}`),
    }),
  },
  institutions: {
    bySlugOrId: (slugOrId: string) => ({
      get: organizationRoute(`/institutions/${slugOrId}`),
    }),
    mostPopular: {
      get: organizationRoute('/institutions/most_popular'),
    },
    autocomplete: {
      get: organizationRoute('/institutions/autocomplete'),
    },
  },
  legalAddress: {
    fromPlaceId: {
      post: route('/users/me/legal_address/from_place_id'),
    },
  },
  onboarding_tasks: {
    get: route('/users/me/onboarding_tasks'),
    put: route('/users/me/onboarding_tasks'),
  },
  riskRatingQuestionnaire: {
    post: route('/users/me/risk_rating_questionnaire'),
    get: route('/users/me/risk_rating_questionnaire'),
  },
  users: {
    get: route('/users/me'),
    put: route('/users/me'),
    delete: route('/users/me'),
    subscription: {
      getAll: route('/users/me/subscription_details'),
      createStripeCheckoutSession: route(
        '/users/me/subscriptions/sessions/checkout'
      ),
      createStripeBillingPortalSession: route(
        '/users/me/subscriptions/sessions/manage'
      ),
    },
  },
  questionnaires: {
    post: route('/users/me/questionnaires'),
  },
  currencies: {
    get: route('/currencies'),
    autocomplete: {
      get: route('/currencies/autocomplete'),
    },
    mostPopular: {
      get: route('/currencies/most_popular'),
    },
  },
  uiConfiguration: {
    webapp: {
      get: route('/ui_config'),
    },
    put: route('/users/me/ui_configuration'),
  },
  auth: {
    sso: {
      get: route('/auth/sso_start'),
    },
  },
  commodities: {
    get: route('/users/me/precious_metals'),
    post: route('/users/me/precious_metals'),
  },
  organizations: {
    get: route('/users/me/organizations'),
  },

  wealth_statements: {
    post: organizationRoute('/wealth_statements'),
  },
  insights: {
    financial_projection: {
      get: organizationRoute('/insights/financial_projection'),
    },
  },
  bank_accounts: {
    post: organizationRoute('/bank_accounts'),
    byId: (id: string) => ({
      put: organizationRoute(`/bank_accounts/${id}`),
      delete: organizationRoute(`/bank_accounts/${id}`),
    }),
  },
  loans: {
    get: organizationRoute('/loans'),
    post: organizationRoute('/loans'),
    byId: (id: string | number) => ({
      get: organizationRoute(`/loans/${id}`),
      put: organizationRoute(`/loans/${id}`),
      steps: {
        get: organizationRoute(`/loans/${id}/steps`),
      },
      delete: organizationRoute(`/loans/${id}`),
    }),
    amortization: {
      get: organizationRoute('/loans/amortization_schedule'),
    },
  },
  other_assets: {
    byId: (id: string) => ({
      put: organizationRoute(`/generic_assets/${id}`),
      delete: organizationRoute(`/generic_assets/${id}`),
    }),
    post: organizationRoute('/generic_assets'),
  },
  precious_metals: {
    autocomplete: {
      get: route('/precious_metals/autocomplete'),
    },
    mostPopular: {
      get: route('/precious_metals/most_popular'),
    },
    byId: (id: string) => ({
      put: organizationRoute(`/precious_metals/${id}`),
      delete: organizationRoute(`/precious_metals/${id}`),
    }),
    post: organizationRoute('/precious_metals'),
  },
  real_estates: {
    get: organizationRoute('/real_estates'),
    post: organizationRoute('/real_estates'),
    byId: (id: string | number) => ({
      delete: organizationRoute(`/real_estates/${id}`),
      get: organizationRoute(`/real_estates/${id}`),
      put: organizationRoute(`/real_estates/${id}`),
    }),
    autocomplete: route('/real_estates/autocomplete'),
    estimable: route('/real_estates/estimable'),
  },
  startups: {
    autocomplete: {
      get: route('/startups/autocomplete'),
    },
    investments: {
      bulk: {
        post: organizationRoute('/startups/investments/bulk'),
      },
    },
    find: {
      get: route(`/users/me/startups/find`),
    },
    byId: (id: number | string) => ({
      put: organizationRoute(`/startups/${id}`),
      investments: {
        byId: (investmentId: number) => ({
          put: organizationRoute(`/startups/${id}/investments/${investmentId}`),
        }),
      },
    }),
  },
  sponsorships: {
    get: route('/users/me/sponsorships'),
  },
  scpis: {
    get: organizationRoute('/scpis'),
    post: organizationRoute('/scpis'),
    byId: (id: string | number) => ({
      delete: organizationRoute(`/scpis/${id}`),
      get: organizationRoute(`/scpis/${id}`),
      put: organizationRoute(`/scpis/${id}`),
    }),
    autocomplete: route('/scpis/autocomplete'),
    mostPopular: route('/scpis/most_popular'),
  },
  crowdlendings: {
    byId: (id: string) => ({
      put: organizationRoute(`/crowdlendings/${id}`),
      delete: organizationRoute(`/crowdlendings/${id}`),
    }),
    post: organizationRoute('/crowdlendings'),
  },
  fonds_euro: {
    byId: (id: number) => ({
      put: organizationRoute(`/fonds_euro/${id}`),
      delete: organizationRoute(`/fonds_euro/${id}`),
    }),
    post: organizationRoute('/fonds_euro'),
  },
  portfolio: {
    get: organizationRoute('/portfolio'),
    assets: {
      fees: {
        get: organizationRoute('/portfolio/assets/fees'),
      },
    },
    asset_leaderboard: {
      get: organizationRoute('/portfolio/assets_leaderboard'),
    },
    checking_accounts: {
      get: organizationRoute('/portfolio/checking_accounts'),
      transactions: {
        get: organizationRoute('/portfolio/checking_accounts/transactions'),
      },
      institutionById: (id: string) => ({
        get: organizationRoute(
          `/portfolio/checking_accounts/institutions/${id}`
        ),
      }),
      accounts: {
        get: organizationRoute('/portfolio/checking_accounts/accounts'),
      },
      distribution: {
        get: organizationRoute('/portfolio/checking_accounts/distribution'),
      },
      timeseries: {
        get: organizationRoute('/portfolio/checking_accounts/timeseries'),
      },
    },
    commodities: {
      get: organizationRoute('/portfolio/commodities'),
      accounts: {
        get: organizationRoute('/portfolio/commodities/accounts'),
      },
      distribution: {
        get: organizationRoute('/portfolio/commodities/distribution'),
      },
      timeseries: {
        get: organizationRoute('/portfolio/commodities/timeseries'),
      },
    },
    credit_accounts: {
      get: organizationRoute('/portfolio/credit_accounts'),
      transactions: {
        get: organizationRoute('/portfolio/credit_accounts/transactions'),
      },
      institutionById: (id: string) => ({
        get: organizationRoute(`/portfolio/credit_accounts/institutions/${id}`),
      }),
      accounts: {
        get: organizationRoute('/portfolio/credit_accounts/accounts'),
      },
      distribution: {
        get: organizationRoute('/portfolio/credit_accounts/distribution'),
      },
      timeseries: {
        get: organizationRoute('/portfolio/credit_accounts/timeseries'),
      },
    },
    crowdlendings: {
      get: organizationRoute('/portfolio/crowdlendings'),
      accounts: {
        get: organizationRoute('/portfolio/crowdlendings/accounts'),
      },
      distribution: {
        get: organizationRoute('/portfolio/crowdlendings/distribution'),
      },
      timeseries: {
        get: organizationRoute('/portfolio/crowdlendings/timeseries'),
      },
    },
    cryptos: {
      get: organizationRoute('/portfolio/cryptos'),
      accountById: (id: string) => ({
        get: organizationRoute(`/portfolio/cryptos/accounts/${id}`),
        put: organizationRoute(`/portfolio/cryptos/accounts/${id}`),
        distribution: {
          get: organizationRoute(
            `/portfolio/cryptos/accounts/${id}/distribution`
          ),
        },
        timeseries: {
          get: organizationRoute(
            `/portfolio/cryptos/accounts/${id}/timeseries`
          ),
        },
      }),
      accounts: {
        get: organizationRoute('/portfolio/cryptos/accounts'),
      },
      distribution: {
        get: organizationRoute('/portfolio/cryptos/distribution'),
      },

      timeseries: {
        get: organizationRoute('/portfolio/cryptos/timeseries'),
      },
    },
    fees: {
      get: organizationRoute('/portfolio/fees'),
    },
    fonds_euro: {
      get: organizationRoute('/portfolio/fonds_euro'),
      accounts: {
        get: organizationRoute('/portfolio/fonds_euro/accounts'),
      },
      timeseries: {
        get: organizationRoute('/portfolio/fonds_euro/timeseries'),
      },
      distribution: {
        get: organizationRoute('/portfolio/fonds_euro/distribution'),
      },
    },
    insights: {
      get: organizationRoute('/portfolio/insights'),
    },
    investments: {
      get: organizationRoute('/portfolio/investments'),
      assets: {
        fees: {
          get: organizationRoute('/portfolio/investments/assets/fees'),
        },
      },
      accountById: (id: string) => ({
        get: organizationRoute(`/portfolio/investments/accounts/${id}`),
        distribution: {
          get: organizationRoute(
            `/portfolio/investments/accounts/${id}/distribution`
          ),
        },
        dividends: {
          get: organizationRoute(
            `/portfolio/investments/accounts/${id}/dividends`
          ),
        },
        fees: {
          get: organizationRoute(`/portfolio/investments/accounts/${id}/fees`),
        },
        geoAllocation: {
          get: organizationRoute(
            `/portfolio/investments/accounts/${id}/geographical_allocation`
          ),
        },
        sectorAllocation: {
          get: organizationRoute(
            `/portfolio/investments/accounts/${id}/sector_allocation`
          ),
        },
        timeseries: {
          get: organizationRoute(
            `/portfolio/investments/accounts/${id}/timeseries`
          ),
        },
      }),
      transactions: {
        get: organizationRoute('/portfolio/investments/transactions'),
      },
      accounts: {
        get: organizationRoute('/portfolio/investments/accounts'),
        fees: {
          get: organizationRoute('/portfolio/investments/accounts/fees'),
        },
      },
      distribution: {
        get: organizationRoute('/portfolio/investments/distribution'),
      },
      dividends: {
        get: organizationRoute('/portfolio/investments/dividends'),
      },
      fees: {
        get: organizationRoute('/portfolio/investments/fees'),
      },
      geoAllocation: {
        get: organizationRoute(
          '/portfolio/investments/geographical_allocation'
        ),
      },
      sectorAllocation: {
        get: organizationRoute('/portfolio/investments/sector_allocation'),
      },
      timeseries: {
        get: organizationRoute('/portfolio/investments/timeseries'),
      },
    },
    loans: {
      get: organizationRoute('/portfolio/loans'),
      accounts: {
        get: organizationRoute('/portfolio/loans/accounts'),
      },
      distribution: {
        get: organizationRoute('/portfolio/loans/distribution'),
      },
      insights: {
        get: organizationRoute('/portfolio/loans/insights'),
      },
    },
    other_assets: {
      get: organizationRoute('/portfolio/other_assets'),
      accounts: {
        get: organizationRoute('/portfolio/other_assets/accounts'),
      },
      distribution: {
        get: organizationRoute('/portfolio/other_assets/distribution'),
      },
      timeseries: {
        get: organizationRoute('/portfolio/other_assets/timeseries'),
      },
    },
    real_estates: {
      get: organizationRoute('/portfolio/real_estates'),
      accountById: (id: string) => ({
        get: organizationRoute(`/portfolio/real_estates/accounts/${id}`),
        dividends: {
          get: organizationRoute(
            `/portfolio/real_estates/accounts/${id}/dividends`
          ),
        },
        fees: {
          get: organizationRoute(`/portfolio/real_estates/accounts/${id}/fees`),
        },
        timeseries: {
          get: organizationRoute(
            `/portfolio/real_estates/accounts/${id}/timeseries`
          ),
        },
        geoAllocation: {
          get: organizationRoute(
            `/portfolio/real_estates/accounts/${id}/geographical_allocation`
          ),
        },
        sectorAllocation: {
          get: organizationRoute(
            `/portfolio/real_estates/accounts/${id}/sector_allocation`
          ),
        },
      }),
      accounts: {
        get: organizationRoute('/portfolio/real_estates/accounts'),
      },
      distribution: {
        get: organizationRoute('/portfolio/real_estates/distribution'),
      },
      dividends: {
        get: organizationRoute('/portfolio/real_estates/dividends'),
      },
      fees: {
        get: organizationRoute('/portfolio/real_estates/fees'),
      },
      geoAllocation: {
        get: organizationRoute(
          '/portfolio/real_estates/geographical_allocation'
        ),
      },
      sectorAllocation: {
        get: organizationRoute('/portfolio/real_estates/sector_allocation'),
      },
      timeseries: {
        get: organizationRoute('/portfolio/real_estates/timeseries'),
      },
    },
    savings_accounts: {
      get: organizationRoute('/portfolio/savings_accounts'),
      transactions: {
        get: organizationRoute('/portfolio/savings_accounts/transactions'),
      },
      institutionById: (id: string) => ({
        get: organizationRoute(
          `/portfolio/savings_accounts/institutions/${id}`
        ),
      }),
      accounts: {
        get: organizationRoute('/portfolio/savings_accounts/accounts'),
      },
      distribution: {
        get: organizationRoute('/portfolio/savings_accounts/distribution'),
      },
      timeseries: {
        get: organizationRoute('/portfolio/savings_accounts/timeseries'),
      },
    },
    startups: {
      get: organizationRoute('/portfolio/startups'),
      accounts: {
        get: organizationRoute('/portfolio/startups/accounts'),
      },
      distribution: {
        get: organizationRoute('/portfolio/startups/distribution'),
      },
      insights: {
        get: organizationRoute('/portfolio/startups/insights'),
      },
      timeseries: {
        get: organizationRoute('/portfolio/startups/timeseries'),
      },
    },
    timeseries: {
      get: organizationRoute('/portfolio/timeseries'),
    },
    dividends: {
      get: organizationRoute('/portfolio/dividends'),
    },
    sector: {
      allocation: {
        get: organizationRoute('/portfolio/sector_allocation'),
      },
      diversification: {
        get: organizationRoute('/portfolio/sector_diversification'),
      },
      suggestion: {
        get: organizationRoute('/portfolio/sector_suggestions'),
      },
    },
    geographical: {
      allocation: {
        get: organizationRoute('/portfolio/geographical_allocation'),
      },
      diversification: {
        get: organizationRoute('/portfolio/geographical_diversification'),
      },
      suggestion: {
        get: organizationRoute('/portfolio/geographical_suggestions'),
      },
    },
    accounts: {
      fees: {
        get: organizationRoute('/portfolio/accounts/fees'),
      },
      byId: (id: string) => ({
        assets: {
          fees: {
            get: organizationRoute(`/portfolio/accounts/${id}/assets/fees`),
          },
        },
        fees: {
          get: organizationRoute(`/portfolio/accounts/${id}/fees`),
        },
      }),
    },
  },
  notifications: {
    get: route('/users/me/notifications'),
    markAllAsViewed: {
      put: organizationRoute('/notifications/mark_all_as_viewed'),
    },
    byId: (id: string) => ({
      put: organizationRoute(`/notifications/${id}`),
    }),
  },
  sync: {
    status: {
      get: route('/users/me/sync_status'),
    },
  },
  plaid: {
    createLinkToken: {
      post: organizationRoute('/plaid/create_link_token'),
    },
  },
  powens: {
    temporaryCode: {
      post: route('/users/me/bi_temporary_code'),
    },
  },
  asset_list: {
    get: organizationRoute('/asset_list'),
    categories: {
      get: organizationRoute('/asset_list/categories'),
    },
  },
  securities: {
    alternatives: {
      get: (securityId: string | number) =>
        organizationRoute(`/securities/${securityId}/alternatives`),
    },
    byId: (id: string | number) => ({
      delete: organizationRoute(`/securities/${id}`),
      put: organizationRoute(`/securities/${id}`),
    }),
    autocomplete: {
      get: route('/securities/autocomplete'),
    },
    mostPopular: {
      get: route('/securities/most_popular'),
    },
    post: organizationRoute('/securities'),
  },
  sharing_links: {
    get: route('/users/me/sharing_links'),
    post: route('/users/me/sharing_links'),
    byId: (id: string) => ({
      get: route(`/users/me/sharing_links/${id}`),
      put: route(`/users/me/sharing_links/${id}`),
      delete: route(`/users/me/sharing_links/${id}`),
      public_info: route(`/users/me/sharing_links/${id}/public_info`),
    }),
  },
  transactions: {
    get: organizationRoute('/transactions'),
    put: organizationRoute('/transactions/bulk_update'),
    byId: (id: number) => ({
      put: organizationRoute(`/transactions/${id}`),
      delete: organizationRoute(`/transactions/${id}`),
    }),
  },
  transactionRules: {
    get: organizationRoute('/transaction_rules'),
    preview: {
      get: organizationRoute('/transaction_rules/preview'),
    },
    post: organizationRoute('/transaction_rules'),
    byId: (id: number) => ({
      preview: {
        get: organizationRoute(`/transaction_rules/${id}/preview`),
      },
      put: organizationRoute(`/transaction_rules/${id}`),
      delete: organizationRoute(`/transaction_rules/${id}`),
    }),
  },
  watches: {
    autocomplete: {
      get: route('/watches/autocomplete'),
    },
    mostPopular: {
      get: route('/watches/most_popular'),
    },
  },
  recaps: {
    latest: {
      get: route('/users/me/recaps/latest'),
    },
  },
  roadmap: {
    get: route('/users/me/roadmap'),
    userChapters: {
      byId: (id: string) => ({
        path: {
          put: route(`/users/me/roadmap/user_chapters/${id}/path`),
        },
        get: route(`/users/me/roadmap/user_chapters/${id}`),
        put: route(`/users/me/roadmap/user_chapters/${id}`),
        reset: {
          post: route(`/users/me/roadmap/user_chapters/${id}/reset`),
        },
      }),
    },
  },
  synchronizations: {
    get: route('/users/me/synchronizations'),
  },
  benchmarks: {
    available_assets: {
      get: organizationRoute('/benchmarks/available_assets'),
    },
  },
  investorProfile: {
    get: organizationRoute('/investor_profile'),
    put: organizationRoute('/investor_profile'),
  },
  dividendPayments: {
    byId: (id: number) => ({
      put: organizationRoute(`/dividend_payments/${id}`),
    }),
  },
};
