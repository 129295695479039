import { Box, BoxProps } from '../Box';
import { IconButton } from '../IconButton';
import { Typography } from '../Typography';

import { paginationItemStyle } from './Pagination.css';

export type PaginationItemProps = Pick<
  React.ComponentProps<'button'>,
  'onClick' | 'children' | 'disabled'
> & {
  active?: boolean;
};

export const PaginationItem = ({
  children,
  onClick,
  active,
  disabled,
}: PaginationItemProps) => {
  return (
    <Box
      as="button"
      className={paginationItemStyle}
      onClick={onClick}
      disabled={disabled}
      data-active={active}
    >
      <Typography variant="labelSmall">{children}</Typography>
    </Box>
  );
};

export type PaginationLinkItemProps = Pick<
  React.ComponentProps<'a'>,
  'href' | 'rel' | 'target' | 'children'
> & {
  active?: boolean;
  disabled?: boolean;
};

export const PaginationLinkItem = ({
  rel,
  href,
  active,
  children,
  disabled,
  target = '_self',
}: PaginationLinkItemProps) => {
  return (
    <Box
      as="a"
      rel={rel}
      href={href}
      target={target}
      data-active={active}
      data-disabled={disabled}
      className={paginationItemStyle}
    >
      <Typography variant="labelSmall">{children}</Typography>
    </Box>
  );
};

export type PaginationProps = Omit<BoxProps, 'onChange'> & {
  current: number;
  max: number;
  min: number;
  onChange: React.Dispatch<React.SetStateAction<number>>;
};

export const Pagination = ({
  children,
  min,
  max,
  current,
  onChange,
  ...props
}: React.PropsWithChildren<PaginationProps>) => {
  return (
    <Box alignItems="center" gap="s2" {...props}>
      <IconButton
        size="small"
        icon="chevronLeft"
        variant="quaternary"
        disabled={current <= min}
        onClick={() => {
          onChange(current - 1);
        }}
      />
      <Box alignItems="center" gap="s1">
        {children}
      </Box>
      <IconButton
        size="small"
        icon="chevronRight"
        variant="quaternary"
        disabled={current >= max}
        onClick={() => {
          onChange(current + 1);
        }}
      />
    </Box>
  );
};
