import {
  ClerkLoaded,
  ClerkLoading,
  ClerkProvider,
  useAuth,
  useSession,
} from '@clerk/nextjs';
import * as Sentry from '@sentry/nextjs';
import Big from 'big.js';
import type { User } from 'core-api';
import { useDisplayCurrency } from 'core-api/hooks/configuration/useDisplayCurrency';
import { useSecretMode } from 'core-api/hooks/configuration/useSecretMode';
import { useUIConfiguration } from 'core-api/hooks/configuration/useUIConfiguration';
import { useSharingLinkPublicInfoById } from 'core-api/hooks/sharing/useSharingLinkPublicInfoById';
import { usePostApi } from 'core-api/hooks/useApi';
import { useOrganization } from 'core-api/hooks/useOrganization';
import { useUser } from 'core-api/hooks/users/useUser';
import { API_ROUTES } from 'core-api/routes';
import {
  QuestionnaireType,
  QuestionnaireOnboarding,
} from 'core-api/types/questionnaire';
import flagsmith from 'flagsmith';
import { FlagsmithProvider, useFlagsmith } from 'flagsmith/react';
import { useAtom } from 'jotai';
import Cookies from 'js-cookie';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { appWithTranslation, i18n, useTranslation } from 'next-i18next';
import { ThemeProvider } from 'next-themes';
import * as React from 'react';
import { Box, Loader, ToasterProvider, useToast } from 'ui';
import { UIProvider, useUIActions, useUIState } from 'ui/providers/UIProvider';
import { darkColors, lightColors } from 'ui/styles/theme.css';
import { matchPattern } from 'utils';

import i18nextConfig from 'next-i18next.config';
import { analytics } from 'services/analytics';

import 'styles/fonts.css';
import 'styles/layout.css';
import 'ui/styles/reset.css';
import 'ui/styles/customScrollbar.css';

import { PUBLIC_PATHS } from '/utils/url';
import { AxeptioScript } from '/components/consent/AxeptioScript';
import { FullPageLoader } from '/components/common/FullPageLoader';
import { PreloadStorageAtoms } from '/components/common/PreloadAtoms';
import { ApiProvider } from '/contexts/api';
import { isUserIdentifiedOnFlagsmithAtom } from '/contexts/flags';
import { showPostSubscriptionModalAtom } from '/contexts/subscription';
import { HistoryProvider } from '/contexts/HistoryProvider';
import { getSupportedLanguage } from '/utils/languages';
import { SharingContext, SharingProvider } from '/contexts/SharingProvider';
import { useIsSharing } from '/hooks/sharing/useIsSharing';
import { SharingAccessCode } from '/components/sharing/SharingAccessCode';
import { useOrganizationState as useOrganizationStateV2 } from '/hooks/useOrganizationState';
import { PageOpenGraph } from '/components/layout/PageOpenGraph';
import { useSharingAuthorization } from '/hooks/useSharingAuthorization';
import { signupValuesAtom } from '/contexts/signup';
import { PAYMENT_STATUS_SUCCEEDED } from '/constants/subscription';
import { useNextTheme } from '/hooks/useNextTheme';
import { ApolloProviderWrapper } from '/contexts/ApolloProvider';

// Big.js configuration for invest
// We always use Big.roundDown as Rounding Mode (RM)
// to avoid display more money than the user has
// Rounds towards zero. I.e. truncate, no rounding.
Big.RM = Big.roundDown;
// We don't want scientific notation for big numbers
// We always want to display all the digits
// as we round with a number of displayed decimals
// We use 18 decimals for all the numbers as it's the maximum
Big.NE = -18;

const FLAGSMITH_ENVIRONMENT_ID = process.env
  .NEXT_PUBLIC_FLAGSMITH_ENVIRONMENT_ID as string;

const PUBLIC_PATH =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview'
    ? `https://${process.env.NEXT_PUBLIC_VERCEL_BRANCH_URL}`
    : process.env.NEXT_PUBLIC_PUBLIC_PATH;

const App = ({ Component, pageProps }: AppProps) => {
  React.useEffect(() => {
    analytics.init();
  }, []);

  const { isSharing, sharingLinkId } = useIsSharing();

  return (
    <ThemeProvider
      attribute="class"
      defaultTheme="dark"
      value={{
        light: lightColors,
        dark: darkColors,
      }}
    >
      <ClerkProvider {...pageProps}>
        <Head>
          <title>Finary</title>
        </Head>
        <PageOpenGraph
          imageSrc={
            isSharing
              ? `${PUBLIC_PATH}/v2/api/sharing/og?sharing_link_id=${sharingLinkId}`
              : `${PUBLIC_PATH}/v2/api/og`
          }
        />
        <ClerkLoading>
          <FullPageLoader />
        </ClerkLoading>
        <ClerkLoaded>
          <FlagsmithProvider
            options={{
              environmentID: FLAGSMITH_ENVIRONMENT_ID,
              cacheFlags: false,
              enableAnalytics: true,
            }}
            flagsmith={flagsmith}
          >
            <ApiProvider>
              <ApolloProviderWrapper>
                <UIProvider>
                  <ToasterProvider>
                    <HistoryProvider>
                      <SharingProvider>
                        <AppWrapper>
                          <Component {...pageProps} />
                          <AxeptioScript />
                        </AppWrapper>
                      </SharingProvider>
                    </HistoryProvider>
                  </ToasterProvider>
                </UIProvider>
              </ApolloProviderWrapper>
            </ApiProvider>
          </FlagsmithProvider>
        </ClerkLoaded>
      </ClerkProvider>
    </ThemeProvider>
  );
};

const AppWrapper = ({ children }: { children: React.ReactNode }) => {
  const [isMounted, setIsMounted] = React.useReducer(() => true, false);
  const { isSignedIn, isLoaded } = useSession();
  const router = useRouter();
  const { locale } = useUIState();
  const { data: user } = useUser();
  const { data: configuration } = useUIConfiguration();
  const { data: organizationData } = useOrganization();
  const { sharingLinkId, accessCode } = useIsSharing();
  const { data: sharingLinkPublicInfo } =
    useSharingLinkPublicInfoById(sharingLinkId);
  useSharingAuthorization();
  const { setAccessCode } = React.useContext(SharingContext);

  useAnalytics();
  useSentryIdentify();
  useSyncSecretMode();
  useUpdateUserLocale();
  useUpdateUserCurrency();
  useQuestionnaireValues();
  const { waitingForPaymentStatus } = usePayementStatusFeedback();
  useUpdateSelectedUserAndOrganization();
  useFlagsmithFeatureFlag();

  const { display_language } = configuration;

  const isReady =
    user &&
    organizationData &&
    locale === display_language &&
    !waitingForPaymentStatus;
  const isNotLoggedIn = isLoaded && !isSignedIn;
  const isPublic = isNotLoggedIn && matchPattern(router.pathname, PUBLIC_PATHS);
  const isSharingPublic = sharingLinkPublicInfo?.public_info;
  const isProtectedSharingWithoutAccessCode =
    isSharingPublic === false && !accessCode;

  React.useEffect(() => {
    if (isPublic || isReady) {
      setIsMounted();
    }
  }, [isPublic, isReady]);

  // Remove access code if not needed anymore
  React.useEffect(() => {
    if (isSharingPublic && accessCode) {
      setAccessCode(undefined);
    }
  }, [accessCode, isSharingPublic, setAccessCode]);

  // Display a loader when the user is not logged in (unless public page)
  return React.useMemo(() => {
    const loader = (
      <Box
        alignItems="center"
        justifyContent="center"
        style={{
          width: isMounted ? 0 : '100%',
          height: isMounted ? 0 : '100%',
          opacity: isMounted ? 0 : 1,
          position: 'absolute',
          left: 0,
          top: 0,
        }}
      >
        <Loader />
        <PreloadStorageAtoms />
      </Box>
    );

    if (isProtectedSharingWithoutAccessCode) {
      return <SharingAccessCode />;
    } else if (isPublic || isReady) {
      return (
        <>
          {loader}
          {children}
        </>
      );
    } else {
      return loader;
    }
  }, [
    children,
    isMounted,
    isProtectedSharingWithoutAccessCode,
    isPublic,
    isReady,
  ]);
};

const useFlagsmithFeatureFlag = () => {
  const { data: user } = useUser();
  const flagsmith = useFlagsmith();
  const [_, setIsUserIdentifiedOnFlagsmith] = useAtom(
    isUserIdentifiedOnFlagsmithAtom
  );

  React.useEffect(() => {
    if (user) {
      flagsmith
        .identify(user.slug, {
          email: user.email,
        })
        .then(() => {
          setIsUserIdentifiedOnFlagsmith(true);
        });
    }
  }, [user, flagsmith, setIsUserIdentifiedOnFlagsmith]);
};

const useAnalytics = () => {
  const router = useRouter();
  const { data: user } = useUser();
  const { resolvedTheme } = useNextTheme();

  // Identify user for analytics
  React.useEffect(() => {
    if (user) {
      analytics.identify(user, { web_theme: resolvedTheme ?? '' });
    }
  }, [user, resolvedTheme]);

  // Track page views
  React.useEffect(() => {
    analytics.page();
  }, [router.pathname]);
};

const useSentryIdentify = () => {
  const { data: user } = useUser();

  React.useEffect(() => {
    if (user) {
      Sentry.setUser({
        id: user.slug,
        email: user.email,
      });
    }
  }, [user]);
};

const useSyncSecretMode = () => {
  const { sharingLinkId, accessCode } = useIsSharing();
  const { toggleSecretMode } = useUIActions();
  const { isSecretMode } = useSecretMode({ sharingLinkId, accessCode });

  // Sync UI state with local storage
  React.useEffect(() => {
    toggleSecretMode(isSecretMode);
  }, [isSecretMode, toggleSecretMode]);
};

const useUpdateUserLocale = () => {
  const { data: configuration } = useUIConfiguration();
  const { setUserLocale } = useUIActions();

  const { display_language } = configuration;
  // Update user locale when available
  React.useEffect(() => {
    if (display_language) {
      i18n?.changeLanguage(display_language).then(() => {
        Cookies.set('NEXT_LOCALE', display_language);
        setUserLocale(display_language);
      });
    } else {
      const supportedLanguage = getSupportedLanguage();
      Cookies.set('NEXT_LOCALE', supportedLanguage);
      setUserLocale(supportedLanguage);
    }
  }, [display_language, setUserLocale]);
};

const useUpdateUserCurrency = () => {
  const { data: configuration } = useUIConfiguration();
  const { setUserCurrency } = useUIActions();

  const { display_currency } = configuration;
  // Update user currency when available
  React.useEffect(() => {
    if (display_currency) {
      setUserCurrency(display_currency.code);
    }
  }, [display_currency, setUserCurrency]);
};

const useQuestionnaireValues = () => {
  const { data: user, update: updateUser } = useUser();
  const { update: updateDisplayCurrency } = useDisplayCurrency();
  const [signupValues, setSignupValues] = useAtom(signupValuesAtom);
  const [hasSavedQuestionnaire, setHasSavedQuestionnaire] =
    React.useState<boolean>(false);
  const saveQuestionnaire = usePostApi<unknown, QuestionnaireOnboarding>(
    API_ROUTES.questionnaires.post()
  );

  // Update user with first/last name and questionnaire values
  React.useEffect(() => {
    if (user && signupValues && !hasSavedQuestionnaire) {
      setHasSavedQuestionnaire(true);

      const {
        investor_level,
        goals,
        source,
        wealth_level,
        asset_tracked,
        blocker,
        country,
      } = signupValues;

      analytics.track(
        {
          event: 'Web Onboarding Questionnaire Saved',
        },
        {
          onboarding_questionnaire: {
            ...signupValues,
            goals: [goals],
          },
        }
      );

      // save questionnaire values to the API
      saveQuestionnaire({
        questionnaire_type: QuestionnaireType.Onboarding,
        questionnaire_attributes: {
          goals: [goals],
          investor_level,
          source,
          blocker,
          country,
          wealth_level,
          asset_tracked,
        },
      })
        .then(() => {
          // clear onboarding values once saved
          setSignupValues(undefined);
        })
        .catch(() => {
          // clear onboarding values if there is an error
          setSignupValues(undefined);
        });
    }
  }, [
    saveQuestionnaire,
    hasSavedQuestionnaire,
    setSignupValues,
    signupValues,
    updateDisplayCurrency,
    updateUser,
    user,
  ]);
};

const usePayementStatusFeedback = () => {
  const toast = useToast();
  const router = useRouter();
  const { t } = useTranslation(['pages']);
  const [_, setShowPostSubscriptionModal] = useAtom(
    showPostSubscriptionModalAtom
  );

  const { data: user } = useUser({
    refreshInterval: () => (waitingForPaymentStatus ? 1000 : 0),
  });
  const lastPaymentStatus = React.useRef<string>();

  // Feedback on payment status
  const { paymentStatus, plan } = router.query;

  const waitingForPaymentStatus =
    paymentStatus === PAYMENT_STATUS_SUCCEEDED &&
    user?.subscription_status === 'free';

  React.useEffect(() => {
    if (
      user &&
      user?.subscription_status !== 'free' &&
      paymentStatus === PAYMENT_STATUS_SUCCEEDED &&
      paymentStatus !== lastPaymentStatus.current
    ) {
      router.replace(router.pathname);
      setShowPostSubscriptionModal(true);
      lastPaymentStatus.current = paymentStatus;
    }
  }, [
    paymentStatus,
    plan,
    router,
    toast,
    t,
    user,
    setShowPostSubscriptionModal,
  ]);

  return {
    waitingForPaymentStatus,
  };
};

const useUpdateSelectedUserAndOrganization = () => {
  const { data: organizationData } = useOrganization();
  const {
    membershipId,
    organizationId,
    setOrganizationId,
    setMembershipId,
    isAllOrganization,
  } = useOrganizationStateV2();
  const { isSignedIn } = useAuth();

  React.useEffect(() => {
    if (!isSignedIn) {
      setOrganizationId(null);
    }
  }, [isSignedIn, setOrganizationId, setMembershipId]);

  const currentOrganizationId = organizationData?.id;
  const memberships = organizationData?.memberships;

  React.useEffect(() => {
    if (
      currentOrganizationId &&
      (!organizationId || organizationId !== currentOrganizationId)
    ) {
      setOrganizationId(currentOrganizationId);
    }
    if (
      /**
       * We only want to check and eventually reset the membershipId when logged in
       * as we want to keep the last selected one
       */
      isSignedIn &&
      memberships.length &&
      (!membershipId ||
        (!isAllOrganization &&
          memberships?.findIndex(({ id }) => id === membershipId) === -1))
    ) {
      const organizationOwner = memberships?.find(
        ({ member }) => (member as User)?.is_organization_owner
      );
      setMembershipId(organizationOwner?.id || null);
    }
  }, [
    isSignedIn,
    currentOrganizationId,
    organizationId,
    membershipId,
    memberships,
    isAllOrganization,
    setOrganizationId,
    setMembershipId,
  ]);
};

export default appWithTranslation(App, i18nextConfig);
