import { Box } from '../Box';
import { Skeleton } from '../Skeleton';

import { treeMapContainerStyles } from './TreeMap.css';

export const TreeMapLoader = () => {
  return (
    <Box className={treeMapContainerStyles} gap="s2">
      <Skeleton
        boxProps={{
          flex: 2,
          style: {
            height: '100%',
          },
        }}
        width="100%"
        height="100%"
        radius={4}
      />
      <Box flexDirection="column" gap="s2" flex={1} style={{ height: '100%' }}>
        <Skeleton
          width="100%"
          height="100%"
          radius={4}
          boxProps={{
            style: {
              height: 'calc(50% - 2px)',
            },
          }}
        />
        <Skeleton
          width="100%"
          height="100%"
          radius={4}
          boxProps={{
            style: {
              height: 'calc(50% - 2px)',
            },
          }}
        />
      </Box>
    </Box>
  );
};
