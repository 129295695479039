import { atomWithStorage } from 'jotai/utils';

export const lifeInsuranceModalCloseTimestampAtom = atomWithStorage<
  string | undefined
>('lifeInsuranceModalCloseTimestamp', undefined);

export const dismissedFinaryPlusModalTimestampAtom = atomWithStorage<
  string | undefined
>('dismissedFinaryPlusModalAtom', undefined);

export const dismissedInvestWithFinaryModalTimestampAtom = atomWithStorage<
  string | undefined
>('dismissedInvestWithFinaryModalAtom', undefined);
