import classNames from 'classnames';
import * as React from 'react';

import { useTimer } from '../../hooks/useTimer';
import { Box, BoxProps } from '../Box';

import {
  CarouselStepperVariants,
  carouselStepperStyles,
} from './CarouselStepper.css';

export type CarouselStepperProps = BoxProps &
  CarouselStepperVariants & {
    activeIndex: number;
    count: number;
    defaultTime?: number;
    isPaused?: boolean;
    onAllEnd?: () => void;
    onSingleEnd?: () => void;
    onStepClick?: (index: number) => void;
  };

export const CarouselStepper = ({
  count,
  activeIndex,
  isPaused,
  onAllEnd,
  defaultTime = 15,
  onSingleEnd,
  size,
  onStepClick,
  ...props
}: CarouselStepperProps) => {
  const { resetTimer, timeRemaining } = useTimer({
    initialTime: defaultTime,
    isPaused,
    allowRestartOnEnd: true,
    onTimerEnd: () => {
      if (activeIndex < count - 1) {
        onSingleEnd?.();
      } else {
        onAllEnd?.();
      }
    },
  });

  React.useLayoutEffect(() => {
    resetTimer();
  }, [activeIndex, resetTimer]);

  const activeProgressPercent =
    ((defaultTime - timeRemaining) * 100) / defaultTime;

  return (
    <Box gap="s3" {...props}>
      {[...new Array(count)].map((_, i) => {
        const isActive = i === activeIndex;

        return (
          <Box
            as="button"
            key={`carousel-stepper-${i}-${isActive}`}
            background="tertiary"
            borderRadius="large"
            overflow="hidden"
            className={classNames(carouselStepperStyles.container({ size }))}
            onClick={() => onStepClick?.(i)}
          >
            <Box className={carouselStepperStyles.emptyBackground} />
            <Box
              className={classNames(carouselStepperStyles.progress, {
                [carouselStepperStyles.active]:
                  // We need this condition to avoid animation while entering/leaving a new story
                  isActive && activeProgressPercent !== 0,
              })}
              style={{
                width: `${
                  isActive ? activeProgressPercent : i < activeIndex ? 100 : 0
                }%`,
              }}
            />
          </Box>
        );
      })}
    </Box>
  );
};
