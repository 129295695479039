import * as React from 'react';
import { ResponsiveContainer, Tooltip, TooltipProps, Treemap } from 'recharts';
import { capitalize } from 'utils';
import { formatNumber } from 'utils/number';

import { CHART_COLORS } from '../../constants/charts';
import { SECRET_DISPLAY_VALUE } from '../../constants/secrets';
import { useUIState } from '../../providers/UIProvider';
import { palette } from '../../styles/theme.css';
import { Box } from '../Box';
import { AssetPieChartData } from '../PieChart';
import { Typography } from '../Typography';

import { treeMapContainerStyles } from './TreeMap.css';

type ContentProps = {
  activeIndex: number;
  height: number;
  index: number;
  root: { height: number; width: number };
  setActiveIndex: (index: number) => void;
  width: number;
  x: number;
  y: number;
} & AssetPieChartData & {
    onSectionClick?: (data: AssetPieChartData) => void;
  };

export type TreeMapProps = {
  data: AssetPieChartData[];
  onSectionClick?: (data: AssetPieChartData) => void;
};

export const TreeMap = React.forwardRef<HTMLDivElement, TreeMapProps>(
  ({ data, onSectionClick }: TreeMapProps, ref) => {
    const [activeIndex, setActiveIndex] = React.useState(-1);

    const sortedData = React.useMemo(() => {
      return [...data]
        .sort((a, b) => b.share - a.share)
        .map((datum) => ({
          ...datum,
          name: datum.name && capitalize(datum.name),
          absAmount:
            Math.abs(datum.amount) || // Treemap won't show negative share values so we need this in order to see them
            datum.share, // If amount is 0, we use share to still show the value (in Sharing mode with values hidden, amount is 0)
        }));
    }, [data]);

    return (
      <Box
        onMouseLeave={() => setActiveIndex(-1)}
        className={treeMapContainerStyles}
        ref={ref}
      >
        <ResponsiveContainer>
          <Treemap
            // fully reset the treemap when data changes
            key={sortedData?.map((datum) => datum.name).join('')}
            data={sortedData}
            content={
              //@ts-expect-error rest of the props are injected by Recharts
              <CustomContent
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                onSectionClick={onSectionClick}
              />
            }
            dataKey="absAmount"
            aspectRatio={1}
            isAnimationActive={false}
          >
            <Tooltip
              content={CustomTooltip}
              offset={0}
              contentStyle={{ overflow: 'visible' }}
              allowEscapeViewBox={{ x: true, y: true }}
            />
          </Treemap>
        </ResponsiveContainer>
      </Box>
    );
  }
);

TreeMap.displayName = 'TreeMap';

const TEXT_RECT_PADDING = 4;

const CustomContent = (props: ContentProps) => {
  const { formatTreeMapData } = useFormatTreeMapData();
  const {
    x,
    y,
    width,
    height,
    name,
    share,
    color,
    amount,
    root,
    index,
    activeIndex,
    redirectRoute,
    setActiveIndex,
    onSectionClick,
  } = props;
  const textRef = React.useRef<HTMLDivElement>(null);
  const [textRect, setTextRect] = React.useState<DOMRect>();

  React.useLayoutEffect(() => {
    if (!textRef.current) {
      return;
    }
    setTextRect(textRef.current.getBoundingClientRect());
    const observer = new ResizeObserver(() => {
      if (textRef.current) {
        setTextRect(textRef.current.getBoundingClientRect());
      }
    });
    observer.observe(textRef.current);
    return () => observer.disconnect();
  }, []);

  // content without name is the total, we don't want to display total because we want spaces between blocks
  if (!name) {
    return null;
  }

  const showText =
    !textRect ||
    (textRect.width < width - TEXT_RECT_PADDING &&
      textRect.height < height - TEXT_RECT_PADDING);

  const isRightEnd = x + width === root.width;
  const isBottomEnd = y + height === root.height;

  const { shareFormatted, amountFormatted } = formatTreeMapData({
    share,
    amount,
  });

  return (
    <g
      opacity={activeIndex === -1 || activeIndex === index ? 1 : 0.5}
      onMouseEnter={() => setActiveIndex(index)}
      onClick={() =>
        onSectionClick?.({
          name,
          share,
          color,
          amount,
          redirectRoute,
        })
      }
      style={{ cursor: onSectionClick ? 'pointer' : 'default' }}
    >
      <rect
        x={x}
        y={y}
        width={isRightEnd ? width : Math.max(width - TEXT_RECT_PADDING, 0)} // logic to have space between blocks but not on the edges to keep border radius
        height={isBottomEnd ? height : Math.max(height - TEXT_RECT_PADDING, 0)}
        fill={color ?? CHART_COLORS[index % CHART_COLORS.length]}
      />
      <foreignObject x={x} y={y} width={width} height={height}>
        <Box
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          style={{
            height: '100%',
            padding: TEXT_RECT_PADDING,
            paddingBottom: TEXT_RECT_PADDING * 2,
            paddingRight: TEXT_RECT_PADDING * 2,
            visibility: showText ? 'visible' : 'hidden',
          }}
        >
          <Box
            ref={textRef}
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            style={{
              minHeight: '100%',
              minWidth: '100%',
              flexShrink: 0,
              color: palette.neutralWhite,
            }}
          >
            <Typography variant="bodySmallEmphasis">
              {amountFormatted}
            </Typography>
            <Box gap="s2" justifyContent="center" style={{ minWidth: '4em' }}>
              <Typography
                variant="bodyXSmall"
                wordBreak="break-all"
                lineClamp={1}
              >
                {name}
              </Typography>
              <Typography variant="bodyXSmall">•</Typography>
              <Typography variant="bodyXSmall">{shareFormatted}</Typography>
            </Box>
          </Box>
        </Box>
      </foreignObject>
    </g>
  );
};

const CustomTooltip = ({
  active,
  payload,
  coordinate,
}: TooltipProps<string, string>) => {
  const data = payload?.[0]?.payload as AssetPieChartData | undefined;
  const [translate, setTranslate] = React.useState('');
  const boxRef = React.useRef<HTMLDivElement>(null);

  React.useLayoutEffect(() => {
    const svg = boxRef.current?.offsetParent?.previousElementSibling;
    const svgRect = svg?.getBoundingClientRect();
    const rect = boxRef.current?.getBoundingClientRect();
    let x = '-50%';
    let y = '-50%';

    if (coordinate?.x && rect && svgRect) {
      if (rect.width / 2 > coordinate.x) {
        x = '0';
      } else if (coordinate.x + rect.width / 2 > svgRect.width) {
        x = '-100%';
      }
    }

    if (coordinate?.y && rect && svgRect) {
      if (rect.height / 2 > coordinate.y) {
        y = '0';
      } else if (coordinate.y + rect.height / 2 > svgRect.height) {
        y = '-100%';
      }
    }

    setTranslate(`translate(${x}, ${y})`);
  }, [coordinate]);

  const { formatTreeMapData } = useFormatTreeMapData();
  if (active && data) {
    const { shareFormatted, amountFormatted } = formatTreeMapData(data);
    return (
      <Box
        ref={boxRef}
        background="overlay"
        backdropFilter="blurMedium"
        borderRadius="medium"
        flexDirection="column"
        padding="s4"
        gap="s2"
        textAlign="center"
        style={{
          color: palette.neutralWhite,
          transform: translate,
        }}
      >
        <Typography variant="bodySmall">{amountFormatted}</Typography>
        <Box gap="s2">
          <Typography variant="bodySmall">{data.name}</Typography>
          <Typography variant="bodySmall">•</Typography>
          <Typography variant="bodySmall">{shareFormatted}</Typography>
        </Box>
      </Box>
    );
  }

  return null;
};

const useFormatTreeMapData = () => {
  const {
    locale: userLocale,
    currency: userCurrency,
    isSecretModeEnabled,
  } = useUIState();

  return {
    formatTreeMapData: ({
      share,
      amount,
    }: Pick<AssetPieChartData, 'amount' | 'share'>) => {
      const amountFormatted = isSecretModeEnabled
        ? SECRET_DISPLAY_VALUE
        : formatNumber(amount, userLocale, {
            style: 'currency',
            currency: userCurrency,
            minimumFractionDigits: 0,
            signDisplay: 'auto',
            maximumFractionDigits: 0,
          });

      const shareFormatted = formatNumber(share / 100, userLocale, {
        style: 'percent',
        minimumFractionDigits: 0,
        signDisplay: 'never',
        maximumFractionDigits: share < 1 ? 2 : 0,
      });

      return {
        amountFormatted,
        shareFormatted,
      };
    },
  };
};
