import * as React from 'react';

const ONE_SECOND = 1000;

export type UseTimerProps = {
  initialTime: number;
  allowRestartOnEnd?: boolean;
  isPaused?: boolean;
  onTimerEnd?: () => void;
};

export const useTimer = ({
  initialTime,
  onTimerEnd,
  allowRestartOnEnd,
  isPaused,
}: UseTimerProps) => {
  const [timeRemaining, setTimeRemaining] = React.useState(initialTime);

  React.useEffect(() => {
    if (timeRemaining > 0 && !isPaused) {
      const timer = setTimeout(() => {
        setTimeRemaining((prev) => prev - 1);
      }, ONE_SECOND);

      return () => clearTimeout(timer);
    }

    if (timeRemaining === 0) {
      onTimerEnd?.();
      if (allowRestartOnEnd) {
        setTimeRemaining(initialTime);
      }
    }
  }, [isPaused, timeRemaining, onTimerEnd, initialTime, allowRestartOnEnd]);

  const resetTimer = React.useCallback(() => {
    setTimeRemaining(initialTime);
  }, [initialTime]);

  return {
    timeRemaining,
    resetTimer,
  };
};
