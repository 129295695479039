import { isValidDate } from '../misc';
import { DateRange } from '../types';

const CHART_1D_OPTIONS: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
};

const CHART_OTHER_OPTIONS: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'numeric',
  year: 'numeric',
};

const CHART_FORMATTERS = {
  fr: {
    '1d': Intl.DateTimeFormat('fr', CHART_1D_OPTIONS),
    other: Intl.DateTimeFormat('fr', CHART_OTHER_OPTIONS),
  },
  en: {
    '1d': Intl.DateTimeFormat('en', CHART_1D_OPTIONS),
    other: Intl.DateTimeFormat('en', CHART_OTHER_OPTIONS),
  },
  de: {
    '1d': Intl.DateTimeFormat('de', CHART_1D_OPTIONS),
    other: Intl.DateTimeFormat('de', CHART_OTHER_OPTIONS),
  },
};

export function formatChartDate(
  date: Date,
  locale?: string,
  period?: DateRange
) {
  const options = period === '1d' ? '1d' : 'other';

  if (locale && locale in CHART_FORMATTERS) {
    return CHART_FORMATTERS[locale as keyof typeof CHART_FORMATTERS][
      options
    ].format(date);
  }

  return CHART_FORMATTERS.en[options].format(date);
}

export function formatDate(
  date: Date,
  locale?: string,
  options?: Intl.DateTimeFormatOptions
): string {
  return Intl.DateTimeFormat(locale, options).format(date);
}

export function formatChartTitleDate(date: Date, locale: string) {
  return formatDate(date, locale, {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  });
}

export function daysDiff(startDate: Date | null, endDate: Date | null): number {
  if (startDate && endDate) {
    const diff = endDate.getTime() - startDate.getTime();

    return Math.floor(diff / (1000 * 60 * 60 * 24));
  } else {
    return NaN;
  }
}

export function getDateFormatBasedOnPeriod(
  period: DateRange
): Intl.DateTimeFormatOptions {
  if (period === '1d') {
    return {
      hour: 'numeric',
      minute: 'numeric',
    };
  }

  return {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  };
}

export function formatMonth(
  date: Date,
  locale?: string,
  option: Intl.DateTimeFormatOptions['month'] = 'long'
) {
  return formatDate(date, locale, {
    month: option,
  });
}

export function formatYear(
  date: Date,
  locale?: string,
  option: Intl.DateTimeFormatOptions['year'] = 'numeric'
) {
  return formatDate(date, locale, {
    year: option,
  });
}

export function monthsDiff(
  startDate: Date | undefined | null,
  endDate: Date | undefined | null
): number {
  if (startDate && endDate) {
    return (
      endDate.getMonth() -
      startDate.getMonth() +
      12 * (endDate.getFullYear() - startDate.getFullYear())
    );
  } else {
    return NaN;
  }
}

export function isSameMonth(dateA: Date, dateB: Date): boolean {
  return (
    dateA.getMonth() === dateB.getMonth() &&
    dateA.getFullYear() === dateB.getFullYear()
  );
}

/**
 * Converts an ISO 8601 date into "YYYY-MM-DD" format.
 * @example
 * const isoDate = "2023-08-09T15:30:00.000Z"; // or could be a Date object
 * const formattedDate = getFormattedDateFromISOString(isoDate);
 * // formattedDate will be "2023-08-09"
 */
export function getFormattedDateFromISOString(
  date: string | Date | undefined | null
): string | undefined {
  if (date && isValidDate(date)) {
    return new Date(date).toISOString().split('T')[0];
  }

  return undefined;
}

/**
 * Returns a new date with the specified number of months added to the given date.
 */
export function addMonths(date: Date, monthsToAdd: number) {
  if (date && monthsToAdd) {
    const newDate = new Date(+date);
    const day = newDate.getDate();

    date.setMonth(date.getMonth() + monthsToAdd, 1);
    const month = date.getMonth();
    date.setDate(day);
    // Necessary to handle edge cases where the day is out of bounds for the new month
    if (date.getMonth() !== month) {
      date.setDate(0);
    }
  }
  return date;
}

export function relativeTime(dateAsString: string, locale: string): string {
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;

  const date = new Date(dateAsString);
  const current = Date.now();
  const elapsed = current - date.getTime();

  const rtf = new Intl.RelativeTimeFormat(locale, {
    numeric: 'auto',
    style: 'long',
  });

  if (elapsed < msPerMinute) {
    return rtf.format(-Math.floor(elapsed / 1000), 'seconds');
  } else if (elapsed < msPerHour) {
    return rtf.format(-Math.floor(elapsed / msPerMinute), 'minutes');
  } else if (elapsed < msPerDay) {
    return rtf.format(-Math.floor(elapsed / msPerHour), 'hours');
  } else if (elapsed < msPerMonth) {
    return rtf.format(-Math.floor(elapsed / msPerDay), 'days');
  } else if (elapsed < msPerYear) {
    return rtf.format(-Math.floor(elapsed / msPerMonth), 'months');
  } else {
    return date.toLocaleDateString(locale);
  }
}

/**
 * Formats a date for input min/max attributes by converting it to a string in "yyyy-mm-dd" format.
 */
export function formatDateForInput(date: Date) {
  return date.toISOString().split('T')[0];
}

export const isDateObjectValid = (date?: Date | string | null) => {
  return !!date && !isNaN(new Date(date).valueOf());
};

/**
 * Format the date YYYY-MM as required for the `type=month` input
 */
export const formatDateForMonthInput = (date?: Date | string | null) => {
  return date
    ? formatDate(new Date(date ?? ''), 'fr-CA', {
        month: '2-digit',
        year: 'numeric',
      })
    : undefined;
};
