import * as React from 'react';

import { Box } from '../Box';
import { Typography } from '../Typography';

import { chartTooltipStyles } from './ChartTooltip.css';

export type ChartTooltipProps = {
  children?: React.ReactNode;
  header?: React.ReactNode;
  minWidth?: number;
};

export const ChartTooltip = ({
  header,
  minWidth,
  children,
}: ChartTooltipProps) => {
  return (
    <Box
      paddingVertical="s4"
      className={chartTooltipStyles.container}
      style={{ minWidth: minWidth }}
    >
      {header ? (
        <Typography variant="bodySmall" color="tertiary">
          <Box
            flex={1}
            justifyContent="space-between"
            paddingHorizontal="s4"
            paddingBottom="s4"
            className={chartTooltipStyles.tooltipHeader}
          >
            {header}
          </Box>
        </Typography>
      ) : undefined}

      <Box flexDirection="column" paddingHorizontal="s4">
        {children}
      </Box>
    </Box>
  );
};
