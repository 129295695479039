import { XAxisProps, YAxisProps } from 'recharts';
import { BaseAxisProps } from 'recharts/types/util/types';

import {
  renderChartGrid,
  renderChartXAxis,
  renderChartYAxis,
} from '../ChartElements';

import type { MultiPoint, Point } from './LineChart';

const AUTO_DOMAIN = ['auto', 'auto'];

/**
 * Recharts doesn't seem to allow us to convert this to a component,
 * so we keep a render function instead.
 */
export const renderGridWithAxis = (
  isMultiline: boolean,
  data: Point[] | MultiPoint[],
  formatXAxis: BaseAxisProps['tickFormatter'],
  formatYAxis: BaseAxisProps['tickFormatter'],
  xTicks?: XAxisProps['ticks'],
  yTicks?: YAxisProps['ticks']
) => {
  return (
    <>
      {renderChartGrid()}
      {renderChartYAxis({
        ticks: yTicks,
        dataKey: isMultiline ? undefined : 'value',
        type: 'number',
        allowDataOverflow: isMultiline ? true : undefined,
        domain: getYAxisDomain(data, isMultiline),
        interval: 'preserveEnd',
        width: 50,
        tickFormatter: formatYAxis,
      })}
      {renderChartXAxis({
        ticks: xTicks,
        domain: AUTO_DOMAIN,
        tickMargin: 10,
        tickFormatter: formatXAxis,
        scale: 'time',
        dataKey: 'date',
        type: 'number',
      })}
    </>
  );
};

const getYAxisDomain = (data: MultiPoint[], isMultiline: boolean) => {
  if (!isMultiline) {
    return ['auto', 'auto'];
  }

  // get all value points
  const points = data.flatMap(
    (point) =>
      Object.entries(point)
        .map(([key, value]) =>
          typeof value === 'number' && key !== 'date'
            ? { key, value }
            : undefined
        )
        .filter(Boolean) as { key: string; value: number }[]
  );

  // max value point
  const maxPoint = points.reduce(
    (prev, current) => {
      return prev && prev.value > current.value ? prev : current;
    },
    { key: '', value: 0 }
  );

  // min value that has the same key as max value point
  const minPoint = points.reduce(
    (prev, current) => {
      return current.value < prev.value && current.key === maxPoint.key
        ? current
        : prev;
    },
    { key: '', value: Infinity }
  );

  // check if the sum of all values for each data points is above the minPoint
  const areEveryDataPointsAbove = data.every((point) => {
    let sum = 0;

    Object.entries(point).forEach(([key, value]) => {
      if (typeof value === 'number' && key !== 'date') {
        sum += value;
      }
    });

    return sum >= minPoint.value;
  });

  // if all data points are above the minPoint, we adjust the Y axis to not start from 0
  if (areEveryDataPointsAbove) {
    return ([_, dataMax]: [number, number]): [number, number] => {
      // we want to start the y axis 10% below the minimum value and 1.36% above the maximum value
      return [minPoint.value * 0.908, dataMax * 1.0136];
    };
  }

  return ['auto', 'auto'];
};
