import { Box } from '../Box';

import { Avatar, AvatarProps } from './Avatar';
import { avatarGroupStyles } from './AvatarGroup.css';

const MAX_DISPLAYED_AVATARS = 3;

export type AvatarGroupAvatar = {
  membershipId: string;
} & Omit<AvatarProps, 'variant' | 'size' | 'style' | 'withoutTooltip'>;

export type AvatarGroupProps<T extends AvatarGroupAvatar> = {
  avatars: T[];
  getMoreLabel: (count: number, remainingAvatars: T[]) => React.ReactNode;
  avatarStyle?: AvatarProps['style'];
  onClick?: () => void;
  selectedMembershipId?: T['membershipId'] | null;
};

export const AvatarGroup = <T extends AvatarGroupAvatar>({
  avatars,
  avatarStyle,
  selectedMembershipId,
  onClick,
  getMoreLabel,
}: AvatarGroupProps<T>) => {
  const sortedAvatars = selectedMembershipId
    ? [...avatars].sort((a, b) =>
        a.membershipId === selectedMembershipId
          ? -1
          : b.membershipId === selectedMembershipId
          ? 1
          : 0
      )
    : avatars;

  const cappedAvatars =
    sortedAvatars.length > MAX_DISPLAYED_AVATARS
      ? sortedAvatars.slice(0, MAX_DISPLAYED_AVATARS - 1)
      : sortedAvatars;
  const remainingAvatars = sortedAvatars.slice(cappedAvatars.length);
  const remainingTotal = remainingAvatars.length;

  return (
    <Box<'button'>
      as={onClick ? 'button' : undefined}
      onClick={
        onClick
          ? (event) => {
              event.stopPropagation();
              onClick();
            }
          : undefined
      }
      className={avatarGroupStyles}
    >
      {cappedAvatars.map((avatar) =>
        avatar.icon ? (
          <Avatar
            isHoverable={!!onClick}
            style={avatarStyle}
            key={avatar.membershipId}
            name={avatar.name}
            icon={avatar.icon}
            variant={
              avatar.membershipId === selectedMembershipId
                ? 'tertiary'
                : 'secondary'
            }
          />
        ) : (
          <Avatar
            isHoverable={!!onClick}
            style={avatarStyle}
            key={avatar.membershipId}
            name={avatar.name}
            initials={avatar.initials as string}
            variant={
              avatar.membershipId === selectedMembershipId
                ? 'tertiary'
                : 'secondary'
            }
          />
        )
      )}
      {remainingTotal ? (
        <Avatar
          isHoverable={!!onClick}
          style={avatarStyle}
          initials={`+${remainingTotal}`}
          name={getMoreLabel(remainingTotal, remainingAvatars)}
        />
      ) : null}
    </Box>
  );
};
