import { SVGProps } from 'react';

import { theme } from '../../styles/theme.css';

export const FinaryLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={24}
    fill="none"
    viewBox="0 0 88 24"
    {...props}
  >
    <g fill={theme.color.palette.brand500} clipPath="url(#a)">
      <path d="M29.733 0c.407 0 .727.003 1.067.054v2.472c-.306-.034-.524-.029-.762-.029-.73 0-1.325.221-1.783.663-.442.442-.662 1.045-.662 1.81v1.459H30.8v2.525h-3.207v10.975h-2.854V8.954h-2.417V6.429h2.417V5.046c0-1.563.45-2.795 1.35-3.695C26.99.45 28.205 0 29.733 0Z" />
      <path
        fillRule="evenodd"
        d="M32.088 2.357v2.786h2.79V2.357h-2.79Zm0 17.571v-13.5h2.79v13.5h-2.79Z"
        clipRule="evenodd"
      />
      <path d="M43.578 6.214c-2.944 0-4.339 2.277-4.623 3.054v-2.84h-2.789v13.5h2.79v-8.047c.077-.699.464-1.423 1.11-2.122.671-.724 1.524-1.086 2.557-1.086 1.86 0 3.202 1.216 3.202 3.622v7.634h2.79V12.14c0-1.94-.49-3.416-1.472-4.425s-2.17-1.5-3.565-1.5ZM56.405 19.929c2.092 0 3.756-1.169 4.36-2.413h.152v2.413h2.722v-13.5h-2.722v2.198h-.151c-.605-1.244-2.269-2.413-4.36-2.413-1.513 0-3.076.584-4.361 1.677-1.286 1.092-2.143 2.971-2.143 5.18 0 2.21.857 4.09 2.143 5.181 1.285 1.093 2.848 1.677 4.36 1.677Zm.353-2.438c-1.109 0-2.092-.381-2.949-1.169-.832-.787-1.26-1.854-1.26-3.25 0-1.397.428-2.464 1.26-3.251.857-.788 1.84-1.169 2.949-1.169 1.134 0 2.117.381 2.949 1.169.857.787 1.285 1.854 1.285 3.25 0 1.397-.428 2.464-1.285 3.251-.832.788-1.815 1.169-2.949 1.169ZM72.599 6.214c-2.419 0-4.265 1.559-4.864 3.663V6.422h-2.808V19.93h2.808v-6.364c.156-1.403.703-2.52 1.613-3.377.91-.857 2.028-1.298 3.329-1.298.312 0 .546.026.728.052V6.266a5.628 5.628 0 0 0-.806-.052ZM81.132 15.909l-4.097-9.695h-2.772l5.52 12.676L77.46 24h2.697l2.173-4.76L88 6.215h-2.772l-4.096 9.695Z" />
      <path
        fillRule="evenodd"
        d="M6 6.429a6 6 0 0 0-6 6h12.03a6 6 0 0 0 6-6H6Zm0 7.5a6 6 0 0 0-6 6h5.59a6 6 0 0 0 6-6H6Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h88v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
