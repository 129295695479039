import {
  faApple,
  faBitcoin,
  faFacebook,
  faGithub,
  faGoogle,
  faInstagram,
  faLinkedin,
  faMeta,
  faReddit,
  faTiktok,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { faSignalBars } from '@fortawesome/pro-duotone-svg-icons/faSignalBars';
import { faSignalBarsFair } from '@fortawesome/pro-duotone-svg-icons/faSignalBarsFair';
import { faSignalBarsGood } from '@fortawesome/pro-duotone-svg-icons/faSignalBarsGood';
import { faSignalBarsWeak } from '@fortawesome/pro-duotone-svg-icons/faSignalBarsWeak';
import {
  faAcorn,
  faArrowDown,
  faArrowDownRight,
  faArrowDownToLine,
  faArrowRight,
  faArrowRightFromArc,
  faArrowRightToArc,
  faArrowsFromLine,
  faArrowsRotate,
  faArrowTrendUp,
  faArrowUpFromBracket,
  faArrowUpRight,
  faBaby,
  faBadgeCheck,
  faBagShopping,
  faBan,
  faBandage,
  faBank,
  faBeerMug,
  faBell,
  faBellConcierge,
  faBicycle,
  faBitcoinSign,
  faBolt,
  faBook,
  faBooks,
  faBookOpen,
  faBoxTaped,
  faBriefcase,
  faBriefcaseArrowRight,
  faBroadcastTower,
  faBuilding,
  faBuildingColumns,
  faBuildingUser,
  faBullseyeArrow,
  faBullseyePointer,
  faBurger,
  faBurgerSoda,
  faBurn,
  faBus,
  faBusinessTime,
  faCake,
  faCalculatorSimple,
  faCalendar,
  faCalendarClock,
  faCamera,
  faCameraMovie,
  faCameraRetro,
  faCampground,
  faCapsules,
  faCartShopping,
  faCar,
  faCarBuilding,
  faCars,
  faCarSide,
  faCarWash,
  faCarWrench,
  faChartCandlestick,
  faChartLineUp,
  faChartLine,
  faChartPie,
  faChartPieSimple,
  faCheck,
  faChevronCircleRight,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleCheck,
  faCircleDollarToSlot,
  faCirclePause,
  faCirclePlay,
  faCircleQuestion,
  faCity,
  faClock,
  faClose,
  faClothesHanger,
  faCocktail,
  faCoin,
  faCoins,
  faCompass,
  faConciergeBell,
  faCookieBite,
  faCopy,
  faCreditCard,
  faCupTogo,
  faDroplet,
  faEarth,
  faEllipsisH,
  faEllipsisVertical,
  faEnvelope,
  faEnvelopeOpenDollar,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLink,
  faEye,
  faEyeSlash,
  faFile,
  faFileContract,
  faFileDownload,
  faFileInvoice,
  faFileLines,
  faFilePdf,
  faFiles,
  faFilesMedical,
  faFingerprint,
  faFire,
  faFootball,
  faGamepadModern,
  faGarage,
  faGasPump,
  faGem,
  faGift,
  faGifts,
  faGlobe,
  faGraduationCap,
  faGrid2,
  faGripLines,
  faHammer,
  faHandHeart,
  faHandHoldingDollar,
  faHandHoldingHand,
  faHandsHoldingDollar,
  faHeadphones,
  faHeart,
  faHeat,
  faHome,
  faHourglass2,
  faHouse,
  faHouseBuilding,
  faHouseCrack,
  faHyphen,
  faIdCard,
  faIndustry,
  faInfoCircle,
  faKey,
  faKitMedical,
  faLandmark,
  faLaptop,
  faLeaf,
  faLegal,
  faLink,
  faListTree,
  faLock,
  faLongArrowLeft,
  faMasksTheater,
  faMagnifyingGlass,
  faMedkit,
  faMegaphone,
  faMessages,
  faMicrochip,
  faMinus,
  faMobile,
  faMoneyBillTransfer,
  faMoneyBillWave,
  faMoneyCheck,
  faMoneyCheckDollar,
  faMoneyCheckDollarPen,
  faMoneyFromBracket,
  faMoon,
  faMoped,
  faMotorcycle,
  faMountains,
  faNewspaper,
  faPaintRoller,
  faPalette,
  faPaperclip,
  faParking,
  faPaw,
  faPawSimple,
  faPen,
  faPercent,
  faPhoneOffice,
  faPiggyBank,
  faPlane,
  faPlug,
  faPlus,
  faPopcorn,
  faPrint,
  faQuestionCircle,
  faQuestionSquare,
  faRadio,
  faReceipt,
  faRocket,
  faRotateExclamation,
  faRulerTriangle,
  faSackDollar,
  faSalad,
  faScaleBalanced,
  faSchool,
  faScrewdriverWrench,
  faSearch,
  faSeedling,
  faServer,
  faShareNodes,
  faShieldCheck,
  faShieldQuartered,
  faShirt,
  faShoppingBasket,
  faSidebar,
  faSliders,
  faSmoking,
  faSnowflake,
  faSparkles,
  faSportsball,
  faSquarePollVertical,
  faStethoscope,
  faStore,
  faSunglasses,
  faSync,
  faTableList,
  faTag,
  faTaxi,
  faTeddyBear,
  faThumbsDown,
  faThumbsUp,
  faTimer,
  faTooth,
  faTrain,
  faTrash,
  faTrees,
  faTrophy,
  faTruck,
  faTvRetro,
  faUmbrellaBeach,
  faUser,
  faUserDoctor,
  faUserGraduate,
  faUserGroup,
  faUsers,
  faFolderOpen,
  faUserPolice,
  faUserTie,
  faUtensils,
  faVault,
  faVideo,
  faWandMagicSparkles,
  faWatch,
  faWavePulse,
  faWineGlass,
  faWineGlassCrack,
  faWrench,
} from '@fortawesome/pro-light-svg-icons';
import {
  faBank as faBankSolid,
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faChartTreeMap as faChartTreeMapSolid,
  faCompass as faCompassSolid,
  faGift as faGiftSolid,
  faGrid2 as faGrid2Solid,
  faLinkSimple as faLinkSimpleSolid,
  faMessages as faMessagesSolid,
  faPieChart as faPieChartSolid,
  faReceipt as faReceiptSolid,
  faScrewdriverWrench as faScrewdriverWrenchSolid,
  faSparkles as faSparklesSolid,
  faSpinner as faSpinnerSolid,
  faSquarePollVertical as faSquarePollVerticalSolid,
  faStar as faStarSolid,
  faUserGroup as faUserGroupSolid,
  faWandMagicSparkles as faWandMagicSparklesSolid,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import * as React from 'react';

import { Animation, animations } from '../../styles/animations.css';
import { Sprinkles, sprinkles } from '../../styles/sprinkles.css';
import { brand } from '../../styles/theme.css';

import { iconStyles, IconsVariants, svgStyles } from './Icon.css';
import { finaryMark } from './custom/finary-mark';

const CUSTOM_ICONS = {
  'finary-mark': finaryMark,
};

const DUOTONE_ICONS = {
  'signal-bars': faSignalBars,
  'signal-bars-weak': faSignalBarsWeak,
  'signal-bars-fair': faSignalBarsFair,
  'signal-bars-good': faSignalBarsGood,
};

export const ICONS = {
  acorn: faAcorn,
  apple: faApple,
  arrowDown: faArrowDown,
  arrowDownRight: faArrowDownRight,
  arrowDownToLine: faArrowDownToLine,
  arrowRight: faArrowRight,
  arrowRightFromArc: faArrowRightFromArc,
  arrowRightToArc: faArrowRightToArc,
  arrowsFromLine: faArrowsFromLine,
  arrowsRotate: faArrowsRotate,
  arrowTrendUp: faArrowTrendUp,
  arrowUpFromBracket: faArrowUpFromBracket,
  arrowUpRight: faArrowUpRight,
  baby: faBaby,
  badgeCheck: faBadgeCheck,
  bagShopping: faBagShopping,
  bandage: faBandage,
  ban: faBan,
  bank: faBank,
  bankSolid: faBankSolid,
  beerMug: faBeerMug,
  bell: faBell,
  bellConcierge: faBellConcierge,
  bicycle: faBicycle,
  bitcoin: faBitcoin,
  bitcoinSign: faBitcoinSign,
  bolt: faBolt,
  book: faBook,
  books: faBooks,
  bookOpen: faBookOpen,
  boxTaped: faBoxTaped,
  briefcase: faBriefcase,
  briefcaseArrowRight: faBriefcaseArrowRight,
  broadcastTower: faBroadcastTower,
  building: faBuilding,
  buildingColumns: faBuildingColumns,
  buildingUser: faBuildingUser,
  bullseyeArrow: faBullseyeArrow,
  bullseyePointer: faBullseyePointer,
  burger: faBurger,
  burgerSoda: faBurgerSoda,
  burn: faBurn,
  bus: faBus,
  businessTime: faBusinessTime,
  cake: faCake,
  calculatorSimple: faCalculatorSimple,
  calendar: faCalendar,
  calendarClock: faCalendarClock,
  camera: faCamera,
  cameraMovie: faCameraMovie,
  cameraRetro: faCameraRetro,
  campground: faCampground,
  capsules: faCapsules,
  cartShopping: faCartShopping,
  car: faCar,
  carBuilding: faCarBuilding,
  caretDown: faCaretDown,
  caretRight: faCaretRight,
  caretUp: faCaretUp,
  cars: faCars,
  carSide: faCarSide,
  carWash: faCarWash,
  carWrench: faCarWrench,
  chartCandlestick: faChartCandlestick,
  chartLine: faChartLine,
  chartLineUp: faChartLineUp,
  chartPie: faChartPie,
  chartPieSimple: faChartPieSimple,
  chartTreeMapSolid: faChartTreeMapSolid,
  check: faCheck,
  chevronCircleRight: faChevronCircleRight,
  chevronDown: faChevronDown,
  chevronLeft: faChevronLeft,
  chevronRight: faChevronRight,
  chevronUp: faChevronUp,
  circleCheck: faCircleCheck,
  circleDollarToSlot: faCircleDollarToSlot,
  circlePause: faCirclePause,
  circlePlay: faCirclePlay,
  circleQuestion: faCircleQuestion,
  city: faCity,
  clock: faClock,
  close: faClose,
  clothesHanger: faClothesHanger,
  cocktail: faCocktail,
  coin: faCoin,
  coins: faCoins,
  compass: faCompass,
  compassSolid: faCompassSolid,
  conciergeBell: faConciergeBell,
  cookieBite: faCookieBite,
  copy: faCopy,
  creditCard: faCreditCard,
  cupTogo: faCupTogo,
  droplet: faDroplet,
  earth: faEarth,
  ellipsisHorizontal: faEllipsisH,
  ellipsisVertical: faEllipsisVertical,
  envelope: faEnvelope,
  envelopeOpenDollar: faEnvelopeOpenDollar,
  exclamationCircle: faExclamationCircle,
  exclamationTriangle: faExclamationTriangle,
  externalLink: faExternalLink,
  eye: faEye,
  eyeSlash: faEyeSlash,
  facebook: faFacebook,
  file: faFile,
  fileContract: faFileContract,
  fileDownload: faFileDownload,
  fileInvoice: faFileInvoice,
  fileLines: faFileLines,
  filePdf: faFilePdf,
  files: faFiles,
  filesMedical: faFilesMedical,
  fingerPrint: faFingerprint,
  fire: faFire,
  folderOpen: faFolderOpen,
  football: faFootball,
  gamepadModern: faGamepadModern,
  garage: faGarage,
  gasPump: faGasPump,
  gem: faGem,
  gift: faGift,
  gifts: faGifts,
  giftSolid: faGiftSolid,
  github: faGithub,
  globe: faGlobe,
  google: faGoogle,
  graduationCap: faGraduationCap,
  grid2: faGrid2,
  grid2Solid: faGrid2Solid,
  gripLines: faGripLines,
  hammer: faHammer,
  handHeart: faHandHeart,
  handHoldingDollar: faHandHoldingDollar,
  handHoldingHand: faHandHoldingHand,
  handsHoldingDollar: faHandsHoldingDollar,
  headphones: faHeadphones,
  heart: faHeart,
  heat: faHeat,
  home: faHome,
  hourglass2: faHourglass2,
  house: faHouse,
  houseBuilding: faHouseBuilding,
  houseCrack: faHouseCrack,
  hyphen: faHyphen,
  idCard: faIdCard,
  industry: faIndustry,
  infoCircle: faInfoCircle,
  instagram: faInstagram,
  key: faKey,
  kitMedical: faKitMedical,
  landmark: faLandmark,
  laptop: faLaptop,
  leaf: faLeaf,
  legal: faLegal,
  link: faLink,
  linkedin: faLinkedin,
  linkSimpleSolid: faLinkSimpleSolid,
  listTree: faListTree,
  lock: faLock,
  longArrowLeft: faLongArrowLeft,
  masksTheater: faMasksTheater,
  magnifyingGlass: faMagnifyingGlass,
  medkit: faMedkit,
  megaphone: faMegaphone,
  messages: faMessages,
  messagesSolid: faMessagesSolid,
  meta: faMeta,
  microchip: faMicrochip,
  minus: faMinus,
  mobile: faMobile,
  moneyBillTransfer: faMoneyBillTransfer,
  moneyBillWave: faMoneyBillWave,
  moneyCheck: faMoneyCheck,
  moneyCheckDollar: faMoneyCheckDollar,
  moneyCheckDollarPen: faMoneyCheckDollarPen,
  moneyFromBracket: faMoneyFromBracket,
  moon: faMoon,
  moped: faMoped,
  motorcycle: faMotorcycle,
  mountains: faMountains,
  newspaper: faNewspaper,
  paintRoller: faPaintRoller,
  palette: faPalette,
  paperclip: faPaperclip,
  parking: faParking,
  paw: faPaw,
  pawSimple: faPawSimple,
  pen: faPen,
  percent: faPercent,
  phoneOffice: faPhoneOffice,
  pieChartSolid: faPieChartSolid,
  piggyBank: faPiggyBank,
  plane: faPlane,
  plug: faPlug,
  plus: faPlus,
  popcorn: faPopcorn,
  print: faPrint,
  questionCircle: faQuestionCircle,
  questionSquare: faQuestionSquare,
  radio: faRadio,
  receipt: faReceipt,
  receiptSolid: faReceiptSolid,
  reddit: faReddit,
  rocket: faRocket,
  rotateExclamation: faRotateExclamation,
  rulerTriangle: faRulerTriangle,
  sackDollar: faSackDollar,
  salad: faSalad,
  scaleBalanced: faScaleBalanced,
  school: faSchool,
  screwdriverWrench: faScrewdriverWrench,
  screwdriverWrenchSolid: faScrewdriverWrenchSolid,
  sparklesSolid: faSparklesSolid,
  search: faSearch,
  seedling: faSeedling,
  server: faServer,
  shareNodes: faShareNodes,
  shieldCheck: faShieldCheck,
  shieldQuartered: faShieldQuartered,
  shirt: faShirt,
  shoppingBasket: faShoppingBasket,
  sidebar: faSidebar,
  sliders: faSliders,
  smoking: faSmoking,
  snowflake: faSnowflake,
  sparkles: faSparkles,
  spinnerSolid: faSpinnerSolid,
  sportsball: faSportsball,
  squarePollVertical: faSquarePollVertical,
  squarePollVerticalSolid: faSquarePollVerticalSolid,
  starSolid: faStarSolid,
  stethoscope: faStethoscope,
  store: faStore,
  sunglasses: faSunglasses,
  sync: faSync,
  tableList: faTableList,
  tag: faTag,
  taxi: faTaxi,
  teddyBear: faTeddyBear,
  thumbsDown: faThumbsDown,
  thumbsUp: faThumbsUp,
  tiktok: faTiktok,
  timer: faTimer,
  tooth: faTooth,
  train: faTrain,
  trash: faTrash,
  trees: faTrees,
  trophy: faTrophy,
  truck: faTruck,
  tvRetro: faTvRetro,
  twitter: faTwitter,
  umbrellaBeach: faUmbrellaBeach,
  user: faUser,
  userDoctor: faUserDoctor,
  userGraduate: faUserGraduate,
  userGroup: faUserGroup,
  userGroupSolid: faUserGroupSolid,
  userPolice: faUserPolice,
  users: faUsers,
  userTie: faUserTie,
  utensils: faUtensils,
  vault: faVault,
  video: faVideo,
  wandMagicSparkles: faWandMagicSparkles,
  wandMagicSparklesSolid: faWandMagicSparklesSolid,
  watch: faWatch,
  wavePulse: faWavePulse,
  wineGlass: faWineGlass,
  wineGlassCrack: faWineGlassCrack,
  wrench: faWrench,
  youtube: faYoutube,
  ...CUSTOM_ICONS,
  ...DUOTONE_ICONS,
} as const;

export type IconName = keyof typeof ICONS;

export type IconSize = NonNullable<Exclude<IconsVariants, undefined>['size']>;

export type IconProps = {
  name: IconName;
  animation?: Animation;
  color?: Sprinkles['color'];
} & IconsVariants &
  Omit<React.ComponentProps<'span'>, 'color'>;

export const Icon = ({
  className,
  color = 'currentColor',
  variant,
  name,
  size,
  animation,
  ...props
}: IconProps): React.ReactElement => {
  return (
    <span
      data-testid={`icon-${name}`}
      className={classNames(
        iconStyles({ size, variant }),
        sprinkles({ color }),
        { 'gradient-icon': variant === 'gradient' },
        className
      )}
      {...props}
    >
      {variant === 'gradient' ? (
        <svg width="0" height="0" viewBox="0 0 1 1">
          <defs>
            <linearGradient id="gradient-icon" x1="0" y1="0" x2="1" y2="1">
              <stop stopColor="#5682F2" />
              <stop offset="0.7908" stopColor={brand.brand400} />
            </linearGradient>
          </defs>
        </svg>
      ) : null}
      <FontAwesomeIcon
        className={classNames(svgStyles, animation && animations[animation])}
        icon={ICONS[name]}
      />
    </span>
  );
};
