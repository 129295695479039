import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';

import { Box } from '../Box';
import { Typography } from '../Typography';
import 'react-phone-input-2/lib/style.css';
import './PhoneNumberField.css';

import { phoneNumberFieldStyles } from './PhoneNumberField.css';

export type PhoneNumberFieldProps = {
  name: string;
  label?: React.ReactNode;
};

export const PhoneNumberField = React.forwardRef<
  HTMLInputElement,
  PhoneNumberFieldProps
>((props, ref) => {
  const { watch, setValue } = useFormContext();
  const value = watch(props.name);

  return (
    <Box flexDirection="column" className={phoneNumberFieldStyles.container}>
      <Typography
        as="label"
        htmlFor={props.name}
        className={phoneNumberFieldStyles.label}
      >
        {props.label}
      </Typography>
      <PhoneInput
        inputProps={{
          ref: ref,
          id: props.name,
        }}
        country="fr"
        placeholder="+33 6 31 07 34 26"
        preferredCountries={['fr', 'gb', 'us']}
        value={value}
        onChange={(value) =>
          setValue(props.name, value, { shouldValidate: true })
        }
      />
    </Box>
  );
});

PhoneNumberField.displayName = 'PhoneNumberField';
