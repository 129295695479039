import { useCopyValue } from '../../hooks/useCopyValue';
import { Box } from '../Box';
import { CopyIcon } from '../CopyIcon/CopyIcon';
import { copyIconStyles } from '../CopyIcon/CopyIcon.css';
import { IconButton, IconButtonProps } from '../IconButton';

export type CopyIconButtonBaseProps = {
  valueToCopy: string;
};

export type CopyIconButtonRawProps = CopyIconButtonBaseProps & {
  variant: 'raw';
};

export type CopyIconButtonVariantsProps = CopyIconButtonBaseProps &
  Omit<IconButtonProps, 'icon' | 'iconClassName' | 'onClick'>;

export type CopyIconButtonProps =
  | CopyIconButtonRawProps
  | CopyIconButtonVariantsProps;

export const CopyIconButton = ({
  valueToCopy,
  variant = 'raw',
  ...props
}: CopyIconButtonProps) => {
  const { feedback, handleCopyValue, lastFeedback } = useCopyValue(valueToCopy);

  const icon = feedback ? (feedback === 'success' ? 'check' : 'close') : 'copy';

  if (variant === 'raw') {
    return (
      <Box as="button" borderRadius="small" onClick={handleCopyValue}>
        <CopyIcon feedback={feedback} lastFeedback={lastFeedback} />
      </Box>
    );
  }

  return (
    <IconButton
      key={icon}
      icon={icon}
      variant={variant}
      onClick={handleCopyValue}
      iconClassName={lastFeedback.current ? copyIconStyles.iconIn : undefined}
      {...props}
    />
  );
};
