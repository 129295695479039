import { CartesianGrid } from 'recharts';

import { theme } from '../../styles/theme.css';

export type RenderChartGridProps = {
  hide?: boolean;
  opacity?: number;
};

export const renderChartGrid = (params?: RenderChartGridProps) => {
  const { hide, opacity = 0.3 } = params ?? {};

  if (hide) {
    return null;
  }
  return (
    <CartesianGrid
      vertical={false}
      stroke={theme.color.border.secondary}
      strokeDasharray={4}
      strokeOpacity={opacity}
      strokeWidth={1}
    />
  );
};
