import { assignInlineVars } from '@vanilla-extract/dynamic';
import classNames from 'classnames';

import { palette } from '../../styles/theme.css';
import { Box, BoxProps } from '../Box';

import {
  progressBarStyles,
  progressColorVar,
  ProgressBarVariants,
} from './ProgressBar.css';

export type ProgressBarProps = {
  percent: number;
} & BoxProps &
  ProgressBarVariants &
  (
    | {
        progressColor?: keyof typeof palette;
        progressGradient?: never;
      }
    | {
        progressGradient: string;
        progressColor?: never;
      }
  );

export const ProgressBar = ({
  percent,
  className,
  progressColor = 'brand500',
  progressGradient,
  size,
  style,
  ...props
}: ProgressBarProps) => {
  return (
    <Box
      className={classNames(progressBarStyles.container({ size }), className)}
      style={{
        ...style,
        ...assignInlineVars({
          [progressColorVar]: progressGradient ?? palette[progressColor],
        }),
      }}
      {...props}
    >
      <Box
        className={progressBarStyles.progress}
        style={{
          width: `${100 - percent}%`,
        }}
      />
    </Box>
  );
};
