import { useCopyValue } from '../../hooks/useCopyValue';
import { CopyIcon } from '../CopyIcon';
import { LinkProps, Link } from '../Link';

export type CopyLinkProps = Omit<LinkProps, 'onClick'> & {
  valueToCopy: string;
};

export const CopyLink = ({
  children,
  valueToCopy,
  iconSize = 'small',
  ...props
}: CopyLinkProps) => {
  const { feedback, handleCopyValue, lastFeedback } = useCopyValue(valueToCopy);

  return (
    <Link onClick={handleCopyValue} {...props}>
      {children}
      <CopyIcon
        size={iconSize}
        feedback={feedback}
        lastFeedback={lastFeedback}
      />
    </Link>
  );
};
