import { useSignIn, useSignUp } from '@clerk/nextjs';
import { OAuthProvider } from '@clerk/types';
import Cookies from 'js-cookie';
import { useFormContext } from 'react-hook-form';

import { getSupportedLanguage } from '/utils/languages';
import { useSsoQueryParams } from '/hooks/useSsoQueryParams';
import { SignupFieldValues } from '/contexts/signup';

export type OAuthFinaryProvider = 'google' | 'apple';

export const useSocialConnection = () => {
  const { signIn } = useSignIn();
  const { signUp } = useSignUp();
  const sso = useSsoQueryParams();
  const { watch } = useFormContext<SignupFieldValues>();

  const signupSource = watch('source');
  const ssoQueryParams = sso ? `?${new URLSearchParams(sso).toString()}` : '';

  const socialSignInWith = (
    strategy: Extract<OAuthProvider, OAuthFinaryProvider>
  ) =>
    signIn?.authenticateWithRedirect({
      strategy: `oauth_${strategy}`,
      redirectUrl: `/v2/oauth-callback${ssoQueryParams}`,
      redirectUrlComplete: `/v2/oauth-success${ssoQueryParams}`,
    });

  const socialSignUpWith = (
    strategy: Extract<OAuthProvider, OAuthFinaryProvider>
  ) =>
    signUp?.authenticateWithRedirect({
      strategy: `oauth_${strategy}`,
      redirectUrl: '/v2/oauth-callback',
      redirectUrlComplete: '/v2/oauth-success?from=signup',
      unsafeMetadata: {
        godfather_referral_id: Cookies.get('referral_id'),
        affiliation_id: Cookies.get('affiliation_id'),
        advisor_slug: Cookies.get('advisor_slug'),
        registration_platform: 'web_app',
        signup_source: Cookies.get('signup_source') || signupSource,
        display_language: getSupportedLanguage(),
      },
    });

  return {
    socialSignInWith,
    socialSignUpWith,
  };
};
