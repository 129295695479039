import { Box, Loader } from 'ui';

import { fullPageLoaderContainerStyles } from '/components/common/FullPageLoader.css';

export const FullPageLoader = () => {
  return (
    <Box className={fullPageLoaderContainerStyles}>
      <Loader />
    </Box>
  );
};
