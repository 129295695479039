import * as React from 'react';
import { useCallback } from 'react';
import { UseFormRegisterReturn, useFormContext } from 'react-hook-form';

import { Box } from '../Box';
import { IconButton } from '../IconButton';
import { Typography } from '../Typography';

export type NumberFieldProps = UseFormRegisterReturn & {
  descriptionLabel: (value: number) => React.ReactNode;
  placeholderLabel: React.ReactNode;
  valueLabel: (value: number) => React.ReactNode;
  shouldAllowZero?: boolean;
};

export const NumberField = React.forwardRef<HTMLDivElement, NumberFieldProps>(
  (
    {
      descriptionLabel,
      valueLabel,
      placeholderLabel,
      shouldAllowZero,
      ...props
    },
    ref
  ) => {
    const { setValue, watch } = useFormContext();

    const value: number | undefined | null = watch(props.name);
    const hasValue = typeof value === 'number';

    const handleMinus = useCallback(() => {
      setValue(
        props.name,
        (shouldAllowZero && value === 0) || (!shouldAllowZero && value === 1)
          ? null
          : (value || 0) - 1
      );
    }, [props.name, setValue, shouldAllowZero, value]);

    const handlePlus = useCallback(() => {
      setValue(props.name, !hasValue && shouldAllowZero ? 0 : (value || 0) + 1);
    }, [hasValue, props.name, setValue, shouldAllowZero, value]);

    return (
      <Box
        ref={ref}
        alignItems="center"
        justifyContent="space-between"
        paddingVertical="s3"
      >
        <Box alignItems="baseline" gap="s2">
          {hasValue ? (
            <Typography
              color="primary"
              fontVariant="tabular-nums"
              variant="bodyLargeEmphasis"
            >
              {valueLabel(value)}
            </Typography>
          ) : null}
          {value !== 0 ? (
            <Typography
              variant={value ? 'bodyMedium' : 'bodyLargeEmphasis'}
              color="tertiary"
            >
              {value ? descriptionLabel(value) : placeholderLabel}
            </Typography>
          ) : null}
        </Box>

        <Box gap="s3" alignItems="center">
          <IconButton
            icon="minus"
            size="medium"
            type="button"
            variant="tertiary"
            disabled={!hasValue}
            aria-disabled={!hasValue}
            onClick={handleMinus}
          />
          <IconButton
            icon="plus"
            size="medium"
            type="button"
            variant="tertiary"
            onClick={handlePlus}
          />
        </Box>
      </Box>
    );
  }
);

NumberField.displayName = 'NumberField';
