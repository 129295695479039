import * as React from 'react';
import ContentLoader from 'react-content-loader';
import {
  ResponsiveContainer,
  PieChart as RechartsPieChart,
  Pie,
  Cell,
  PieProps as RechartPieProps,
} from 'recharts';

import { CHART_COLORS } from '../../constants/charts';
import { theme } from '../../styles/theme.css';
import { Box } from '../Box';

export type PieData<T> = {
  name: string;
  share: number;
  color?: string;
} & T;

export type PieProps<T> = {
  data: PieData<T>[];
  size: number;
  thickness: number;
  activeId?: string | null;
  hide?: boolean;
  onMouseEnterCell?: (cellId: string) => void;
  onMouseLeaveCell?: (cellId?: string) => void;
  sort?: boolean;
};

export type ActivePieCellInfos = {
  index: number;
  pieIndex: number;
};

export type PieChartProps<T = { [key: string]: string | number }> = {
  data: PieProps<T>[];
  animate?: boolean;
  controlledActiveCell?: ActivePieCellInfos;
  endAngle?: number;
  height?: number;
  inert?: boolean;
  onActiveChange?: (infos: ActivePieCellInfos | null) => void;
  onPieClick?: (infos: ActivePieCellInfos | null) => void;
  renderActiveInfos?:
    | React.ReactElement
    | ((infos: ActivePieCellInfos | null) => React.ReactNode);
  startAngle?: number;
} & Pick<RechartPieProps, 'onAnimationEnd' | 'cy' | 'cornerRadius'>;

export const PieChart = <T extends { [key: string]: unknown }>({
  animate,
  data,
  inert,
  onActiveChange,
  onAnimationEnd,
  onPieClick,
  renderActiveInfos,
  controlledActiveCell,
  startAngle = 90,
  endAngle = -360,
  height,
  cornerRadius = 0,
  cy = '50%',
}: PieChartProps<T>) => {
  const timeoutControlledActiveCelleRef =
    React.useRef<ReturnType<typeof setTimeout>>();
  const [activeCell, setActiveCell] = React.useState<ActivePieCellInfos | null>(
    null
  );
  const maxSize = data.reduce((acc, pieConfig) => {
    const pieSize = pieConfig.size;
    return pieSize > acc ? pieSize : acc;
  }, 0);

  React.useEffect(() => {
    // Avoid glitch if the controlled cell is changing too fast
    timeoutControlledActiveCelleRef.current = setTimeout(() => {
      setActiveCell(controlledActiveCell ?? null);
    }, 100);

    return () => clearTimeout(timeoutControlledActiveCelleRef.current);
  }, [controlledActiveCell]);

  const onUpdateActiveCell = (infos: ActivePieCellInfos | null) => {
    if (inert) {
      return;
    }
    setActiveCell(infos);
    onActiveChange && onActiveChange(infos);
  };
  const safeHeight = height ?? maxSize;

  return (
    <Box
      position="relative"
      style={{
        width: maxSize,
        minWidth: maxSize,
        height: safeHeight,
        minHeight: safeHeight,
      }}
      onMouseLeave={() => onUpdateActiveCell(null)}
    >
      <ResponsiveContainer width="100%" height={height}>
        <RechartsPieChart
          width={maxSize}
          height={safeHeight}
          style={{ display: 'flex' }}
          margin={{ top: 0, bottom: 0, left: 0, right: 0 }}
        >
          {data.map((pie, pieIndex) => {
            const { data: pieData, size, thickness, hide } = pie;

            return hide ? null : (
              <Pie
                key={pieIndex}
                data={pieData}
                cx="50%"
                cy={cy}
                cornerRadius={cornerRadius}
                allowReorder="yes"
                startAngle={startAngle}
                endAngle={endAngle}
                minAngle={1}
                innerRadius={size / 2 - thickness}
                outerRadius={size / 2}
                dataKey="share"
                onMouseEnter={(_, index) => {
                  onUpdateActiveCell({ pieIndex, index });
                }}
                onClick={
                  inert || !onPieClick
                    ? undefined
                    : (_, index) => onPieClick?.({ pieIndex, index })
                }
                onMouseLeave={() => onUpdateActiveCell(null)}
                isAnimationActive={!!animate}
                onAnimationEnd={onAnimationEnd}
              >
                {pieData.map((value, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={
                      value.color
                        ? value.color
                        : CHART_COLORS[index % CHART_COLORS.length]
                    }
                    cursor={!!onPieClick && !inert ? 'pointer' : 'default'}
                    stroke="transparent"
                    strokeWidth={0}
                    opacity={
                      (index === activeCell?.index &&
                        pieIndex === activeCell?.pieIndex) ||
                      activeCell == null
                        ? 1
                        : 0.5
                    }
                  />
                ))}
              </Pie>
            );
          })}
        </RechartsPieChart>
      </ResponsiveContainer>
      {renderActiveInfos ? (
        <Box
          position="absolute"
          style={{
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            pointerEvents: 'none',
          }}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          {typeof renderActiveInfos === 'function'
            ? renderActiveInfos(activeCell)
            : renderActiveInfos}
        </Box>
      ) : null}
    </Box>
  );
};

export type MultiLevelChartLoaderPieConfig = {
  size: number;
  thickness: number;
};

export type MultiLevelChartLoaderProps = {
  config: MultiLevelChartLoaderPieConfig[];
};

export const MultiLevelChartLoader = ({
  config,
}: MultiLevelChartLoaderProps) => {
  const maskId = React.useId();
  const maxSize = config.reduce((acc, config) => {
    const pieSize = config.size;
    return pieSize > acc ? pieSize : acc;
  }, 0);

  return (
    <svg
      width={maxSize}
      height={maxSize}
      viewBox={`0 0 ${maxSize} ${maxSize}`}
      style={{ minWidth: maxSize, minHeight: maxSize }}
    >
      <defs>
        <mask id={maskId}>
          {config.map((config, index) => {
            const { size, thickness } = config;
            return (
              <React.Fragment key={`${maskId}-${index}`}>
                <circle
                  cx={maxSize / 2}
                  cy={maxSize / 2}
                  r={size / 2}
                  fill="white"
                />
                <circle
                  cx={maxSize / 2}
                  cy={maxSize / 2}
                  r={size / 2 - thickness}
                  fill="black"
                />
              </React.Fragment>
            );
          })}
        </mask>
      </defs>

      <ContentLoader
        mask={`url(#${maskId})`}
        viewBox={`0 0 ${maxSize} ${maxSize}`}
        backgroundColor={theme.color.surface.overlay}
        foregroundColor={theme.color.surface.disabled}
      >
        <circle cx={maxSize / 2} cy={maxSize / 2} r={maxSize / 2} />
      </ContentLoader>
    </svg>
  );
};
