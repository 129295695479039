import { theme } from '../../styles/theme.css';
import { Box } from '../Box';
import { NumberFormatProps, NumberFormat } from '../NumberFormat';
import { TagProps, Tag } from '../Tag';
import { Typography } from '../Typography';

type ChartLegendItemProps = {
  title: string;
  backgroundColor?: string;
  disabled?: boolean;
  hasPlusAccess?: boolean;
  percent?: NumberFormatProps['value'];
  tagVariant?: TagProps['variant'];
  value?: NumberFormatProps['value'];
};

export const ChartLegendItem = ({
  backgroundColor,
  title,
  value,
  percent,
  tagVariant,
  hasPlusAccess,
  disabled,
}: ChartLegendItemProps) => {
  return (
    <Box flexDirection="column" gap="s3" style={{ minWidth: 150 }}>
      <Box flexDirection="row" alignItems="center" gap="s3">
        <Box
          borderRadius="xsmall"
          style={{
            width: theme.spacing.s4,
            height: theme.spacing.s4,
            backgroundColor,
          }}
        />
        <Typography variant="bodySmall" color="tertiary">
          {title}
        </Typography>
      </Box>
      {disabled ? (
        <Typography color="disabled">-</Typography>
      ) : (
        <Box flexDirection="row" alignItems="center" gap="s2">
          <NumberFormat
            value={value}
            type="currency"
            color="primary"
            hideValue={!hasPlusAccess}
          />
          {percent !== undefined ? (
            <Tag variant={tagVariant} size="small">
              <NumberFormat type="percent" value={percent} digit={2} />
            </Tag>
          ) : null}
        </Box>
      )}
    </Box>
  );
};
