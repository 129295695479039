import { Area } from 'recharts';

import { theme } from '../../styles/theme.css';

import { Dot } from './Dot';
import { LineData, MultiLineData } from './LineChart';
import { getMultiValueDataKey } from './util';

type RenderAreaChartProps = {
  gradientID: string;
  isPerformance: boolean;
  lineID: string;
  rawData: LineData | MultiLineData[];
  baseValue?: number;
};

/**
 * Recharts doesn't seem to allow us to convert this to a component,
 * so we keep a render function instead.
 */
export const renderAreaChartAreas = ({
  gradientID,
  isPerformance,
  lineID,
  rawData,
  baseValue,
}: RenderAreaChartProps) => {
  const isMultiline = Array.isArray(rawData);

  if (isMultiline) {
    return rawData.map(({ dataKey, lineType = 'monotone', color }, index) => {
      const key = getMultiValueDataKey(dataKey, index);
      return (
        <Area
          key={key}
          dataKey={key}
          stackId="1"
          type={lineType}
          fill={`url(#${gradientID}_${index})`}
          stroke={color ? color : theme.color.action.default}
          isAnimationActive={false}
          activeDot={(props) => <Dot {...props} />}
        />
      );
    });
  }

  return (
    <Area
      dataKey="value"
      baseValue={baseValue}
      animationDuration={300}
      fill={`url(#${gradientID})`}
      type={rawData.lineType ?? 'monotone'}
      stroke={isPerformance ? `url(#${lineID})` : theme.color.action.default}
      activeDot={(props) => (
        <Dot
          {...props}
          fill={
            isPerformance
              ? props.payload.value >= (baseValue ?? 0)
                ? theme.color.text.success
                : theme.color.text.error
              : theme.color.action.default
          }
        />
      )}
    />
  );
};
