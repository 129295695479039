import { Box, BoxProps } from '../Box';
import { Icon } from '../Icon';
import { Separator } from '../Separator';
import { Typography } from '../Typography';

import { sideStepperItemStyles } from './SideStepper.css';

export type SideStepperSubStep = {
  key: string;
  title: string;
  completed?: boolean;
  error?: boolean;
};

export type SideStepperStep = SideStepperSubStep & {
  completed?: boolean;
  subSteps?: SideStepperSubStep[];
};

export type SideStepperProps = {
  currentStepKey: string;
  onStepClick: (stepKey: string) => void;
  steps: SideStepperStep[];
};

export const SideStepper = ({
  steps,
  currentStepKey,
  onStepClick,
}: SideStepperProps) => {
  const activeIndex = steps?.findIndex(
    (step) =>
      step.key === currentStepKey ||
      step.subSteps?.some((subStep) => subStep.key === currentStepKey)
  );

  return (
    <Box
      as="aside"
      flexDirection="column"
      paddingVertical="s8"
      paddingHorizontal="s6"
    >
      <Box flexDirection="column" as="ol">
        {steps.map((step, index) => {
          const isStepActive = activeIndex === index;

          const isLastStep = index === steps.length - 1;
          const isStepDisabled =
            activeIndex < index &&
            !step.completed &&
            !step.subSteps?.some((subStep) => subStep.completed);
          const isStepError =
            step.error || step.subSteps?.some((subStep) => subStep.error);

          return (
            <Box as="li" flexDirection="column" key={step.key}>
              <SideStepperItem
                index={index}
                disabled={isStepDisabled}
                title={step.title}
                error={isStepError}
                active={isStepActive}
                completed={step.completed}
                onClick={() => onStepClick(step.key)}
              />
              {isLastStep && !step.subSteps ? null : (
                <Box flexDirection="row" paddingVertical="s2">
                  <Separator
                    variant="vertical"
                    marginHorizontal="s6"
                    style={{ minHeight: 24 }}
                  />
                  {step.subSteps && (isStepActive || isStepError) ? (
                    <Box
                      as="ul"
                      flexDirection="column"
                      gap="s2"
                      style={{ width: '100%' }}
                    >
                      {step.subSteps.map((subStep, subStepIndex) => {
                        const subActive = subStep.key === currentStepKey;

                        const subStepActiveIndex =
                          step.subSteps?.findIndex(
                            (subStep) => subStep.key === currentStepKey
                          ) ?? 0;

                        const isSubStepDisabled =
                          subStepActiveIndex < subStepIndex &&
                          !step.completed &&
                          !subStep.completed;

                        return (
                          <SubSideStepperItem
                            key={subStep.key}
                            title={subStep.title}
                            active={subActive}
                            error={subStep.error}
                            disabled={isStepDisabled || isSubStepDisabled}
                            onClick={() => onStepClick(subStep.key)}
                          />
                        );
                      })}
                    </Box>
                  ) : null}
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export type SideStepperItemProps = SubSideStepperItemProps & {
  index: number;
  completed?: boolean;
};

export const SideStepperItem = ({
  active,
  index,
  title,
  completed,
  disabled,
  error,
  onClick,
}: SideStepperItemProps) => {
  return (
    <Box
      as="button"
      padding="s4"
      onClick={onClick}
      disabled={disabled}
      className={sideStepperItemStyles.container({ error, active })}
    >
      {error ? (
        <Box className={sideStepperItemStyles.iconContainer}>
          <Icon name="exclamationCircle" size="medium" />
        </Box>
      ) : completed && !active ? (
        <Box className={sideStepperItemStyles.iconContainer}>
          <Icon name="circleCheck" size="medium" />
        </Box>
      ) : (
        <Box className={sideStepperItemStyles.indexContainer({ active })}>
          <Typography variant="bodyMediumEmphasis">{index + 1}</Typography>
        </Box>
      )}

      <Typography variant="bodyMedium">{title}</Typography>
    </Box>
  );
};

export type SubSideStepperItemProps = {
  title: string;
  active?: boolean;
  disabled?: boolean;
  error?: boolean;
  onClick?: BoxProps<'button'>['onClick'];
};

export const SubSideStepperItem = ({
  active,
  title,
  error,
  disabled,
  onClick,
}: SubSideStepperItemProps) => {
  return (
    <Box
      as="button"
      onClick={onClick}
      disabled={disabled}
      paddingVertical="s3"
      paddingHorizontal="s4"
      className={sideStepperItemStyles.container({ error, active })}
    >
      <Typography flex={1} variant="bodyMedium">
        {title}
      </Typography>
      {error ? <Icon name="exclamationCircle" size="medium" /> : null}
    </Box>
  );
};

export const getSideStepperNextStep = (
  steps: SideStepperStep[],
  currentStepKey: string
): SideStepperSubStep | SideStepperStep | undefined => {
  const mainStepActiveIndex = steps?.findIndex(
    (step) =>
      step.key === currentStepKey ||
      step.subSteps?.some((subStep) => subStep.key === currentStepKey)
  );

  const currentStep = steps[mainStepActiveIndex];

  const currentSubStepIndex = currentStep?.subSteps?.findIndex(
    (subStep) => subStep.key === currentStepKey
  );

  const nextSubStep =
    currentStep &&
    typeof currentSubStepIndex === 'number' &&
    currentSubStepIndex !== -1
      ? currentStep?.subSteps?.[currentSubStepIndex + 1]
      : undefined;

  const nextMainStep = steps[mainStepActiveIndex + 1];

  if (nextSubStep) {
    return nextSubStep;
  } else if (nextMainStep) {
    if (nextMainStep.subSteps?.[0]) {
      return nextMainStep.subSteps[0];
    } else {
      return nextMainStep;
    }
  }
};
