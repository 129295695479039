import * as ToastPrimitive from '@radix-ui/react-toast';
import React from 'react';

import { Box } from '../Box';
import { Icon, IconName } from '../Icon';
import { Link, LinkProps } from '../Link';
import { Typography } from '../Typography';

import { ToastVariants, toastStyles } from './Toast.css';

export type ToastVariant = Exclude<
  Exclude<ToastVariants, undefined>['variant'],
  undefined
>;

export type ToastContentProps = {
  title: React.ReactNode;
  body?: React.ReactNode;
  link?: LinkProps;
  variant?: ToastVariant;
};

export type ToastProps = Omit<ToastPrimitive.ToastProps, 'title'> &
  ToastContentProps &
  ToastVariants;

export const ToastProvider = ToastPrimitive.Provider;

export const ToastViewport = React.forwardRef<
  React.ElementRef<typeof ToastPrimitive.Viewport>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitive.Viewport>
>((props, ref) => (
  <ToastPrimitive.Viewport
    ref={ref}
    className={toastStyles.viewport}
    {...props}
  />
));

ToastViewport.displayName = ToastPrimitive.Viewport.displayName;

const TOAST_ICON: Record<ToastVariant, IconName> = {
  danger: 'exclamationTriangle',
  informational: 'infoCircle',
  success: 'check',
  warning: 'exclamationCircle',
};

export const Toast = React.forwardRef<
  React.ElementRef<typeof ToastPrimitive.Root>,
  ToastProps
>(
  (
    { variant = 'informational', title, body, link, ...props },
    forwardedRef
  ) => {
    return (
      <ToastPrimitive.Root
        ref={forwardedRef}
        className={toastStyles.root({ variant })}
        {...props}
      >
        <Box alignItems="flex-start" gap="s6">
          <Box alignItems="flex-start" flex={1} gap="s5">
            <Box
              padding="s2"
              borderRadius="full"
              className={toastStyles.iconBackground({ variant })}
            >
              <Icon
                name={TOAST_ICON[variant]}
                size="small"
                className={toastStyles.icon({ variant })}
              />
            </Box>
            <Typography
              as={ToastPrimitive.Title}
              paddingVertical="s1"
              variant="bodyMediumEmphasis"
            >
              {title}
            </Typography>
          </Box>
          <ToastPrimitive.Close>
            <Icon
              name="close"
              size="large"
              className={toastStyles.closeIcon({ variant })}
            />
          </ToastPrimitive.Close>
        </Box>
        <Typography
          as={ToastPrimitive.Description}
          paddingLeft="s8"
          variant="bodyMedium"
          color="primary"
        >
          {body}
        </Typography>
        {link ? (
          <Box paddingLeft="s8" paddingTop="s4">
            <Link suffixIcon="chevronRight" size="medium" {...link} />
          </Box>
        ) : null}
      </ToastPrimitive.Root>
    );
  }
);

Toast.displayName = 'Toast';
