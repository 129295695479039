/**
 * Aligns a set of data to 0 and up
 */
export const alignToZero = <T>(
  data: T[],
  getValue: (entry: T) => number,
  setValue: (entry: T, value: number) => T,
  paddingRatio = 0
) => {
  if (data.length === 0) {
    return data;
  }
  const min = data.reduce((acc, val) =>
    acc && getValue(acc) < getValue(val) ? acc : val
  );
  const max = data.reduce((acc, val) =>
    acc && getValue(acc) > getValue(val) ? acc : val
  );
  const distance = getValue(max) - getValue(min);
  const computedPadding = distance * paddingRatio;
  const delta = computedPadding - getValue(min);
  return data.map((entry) => setValue(entry, getValue(entry) + delta));
};
