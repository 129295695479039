import { useSession, useSignIn } from '@clerk/nextjs';
import { ClerkAPIResponseError } from '@clerk/shared';
import { useAtom } from 'jotai';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import * as React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  Box,
  Button,
  ErrorMessage,
  Form,
  Link,
  PasswordTextField,
  Separator,
  TextField,
  Typography,
  FinaryLogo,
} from 'ui';
import { MEDIUM_CONTENT_SIZE } from 'ui/styles/contentSize';
import { theme } from 'ui/styles/theme.css';

import { PreboardingSidePanel } from '/components/Preboarding/PreboardingSidePanel';
import { SocialConnection } from '/components/auth/SocialConnection';
import { FullPageLoader } from '/components/common/FullPageLoader';
import { PageTitle } from '/components/layout/PageTitle';
import { useLogin } from '/hooks/useLogin';
import { useLogout } from '/hooks/useLogout';
import { useSsoQueryParams } from '/hooks/useSsoQueryParams';
import { getServerSidePropsFactory } from '/utils/serverSideProps';
import { APP_ROUTE } from '/utils/url';
import { useHandleSsoRedirection } from '/hooks/useHandleSsoRedirection';
import { impersonatedClerkTicketAtom } from '/contexts/impersonate';
import { ClerkError } from '/components/common/ClerkError';

const Signin = () => {
  useHandleSsoRedirection();
  const router = useRouter();
  const [impersonatedClerkTicket, setImpersonatedClerkTicket] = useAtom(
    impersonatedClerkTicketAtom
  );
  const { t } = useTranslation(['pages']);
  const { signIn, setActive } = useSignIn();
  const { isSignedIn } = useSession();
  const { handleLoginSuccess, handleLoginError } = useLogin();
  const logout = useLogout();
  const [signInError, setSignInError] = React.useState<
    ClerkAPIResponseError | Error
  >();

  // Impersonate a different user
  const impersonate = React.useCallback(
    async (clerkTicket: string) => {
      try {
        if (signIn && clerkTicket !== impersonatedClerkTicket) {
          await logout();
          setImpersonatedClerkTicket(clerkTicket);
          const clerkResponse = await signIn.create({
            strategy: 'ticket',
            ticket: clerkTicket,
          });
          await setActive?.({ session: clerkResponse.createdSessionId });
          await handleLoginSuccess();
        }
      } catch (err: unknown) {
        setSignInError(err as ClerkAPIResponseError);
        handleLoginError(err as ClerkAPIResponseError);
        router.replace(APP_ROUTE.login());
      }
    },
    [
      handleLoginError,
      handleLoginSuccess,
      logout,
      router,
      signIn,
      setActive,
      impersonatedClerkTicket,
      setImpersonatedClerkTicket,
    ]
  );

  // Trigger the impersonate if required
  React.useEffect(() => {
    const clerkTicket = router.query.__clerk_ticket;

    if (typeof clerkTicket === 'string') {
      impersonate(clerkTicket);
    }
  }, [impersonate, router.query]);

  return isSignedIn ? (
    <FullPageLoader />
  ) : (
    <Box style={{ width: '100%', height: '100%' }}>
      <PageTitle title={t('pages:signin.page_title')} />
      <Box
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        overflowY="auto"
        overflowX="hidden"
        flex={1}
        style={{
          height: '100%',
          maxHeight: '100dvh',
        }}
      >
        <Box
          margin="s7"
          flexDirection="column"
          paddingHorizontal="s5"
          style={{
            maxWidth: MEDIUM_CONTENT_SIZE,
            width: '100%',
            paddingBottom: theme.spacing.s6,
          }}
        >
          <FinaryLogo style={{ marginBottom: theme.spacing.s10 }} />
          <SigninForm
            signInError={signInError}
            setSignInError={setSignInError}
          />
        </Box>
      </Box>

      <PreboardingSidePanel />
    </Box>
  );
};

export type SigninFormValues = { email: string; password: string };

export type SigninFormProps = {
  setSignInError: React.Dispatch<
    React.SetStateAction<ClerkAPIResponseError | Error | undefined>
  >;
  signInError?: ClerkAPIResponseError | Error;
};

const SigninForm = ({ signInError, setSignInError }: SigninFormProps) => {
  const { t } = useTranslation(['pages']);
  const router = useRouter();
  const form = useForm<SigninFormValues>();
  const { signIn, setActive } = useSignIn();
  const { handleLoginSuccess, handleLoginError } = useLogin();
  const sso = useSsoQueryParams();

  // Handle the form submission
  const handleSubmitForm: SubmitHandler<SigninFormValues> = async ({
    email,
    password,
  }) => {
    try {
      if (signIn && setActive) {
        const clerkResponse = await signIn.create({
          identifier: email,
          password,
        });

        if (clerkResponse.status === 'needs_second_factor') {
          router.push(APP_ROUTE.login_otp(sso));
          return;
        }

        await setActive({ session: clerkResponse.createdSessionId });
        // Sso redirection is handled in useHandleSsoRedirection of the Signin component
        if (!sso) {
          await handleLoginSuccess();
        }
      } else {
        const error = new Error(t('pages:signin.error'));
        setSignInError(error);
        handleLoginError(error);
      }
    } catch (err: unknown) {
      setSignInError(err as ClerkAPIResponseError);
      handleLoginError(err as ClerkAPIResponseError);
    }
  };

  return (
    <Form form={form} onSubmit={handleSubmitForm}>
      <Box
        flexDirection="column"
        gap="s7"
        style={{ maxWidth: MEDIUM_CONTENT_SIZE, width: '100%' }}
      >
        <Box gap="s3" flexDirection="column">
          <Typography as="h1" variant="displaySmall">
            {t('pages:signin.title')}
          </Typography>
          <Typography variant="bodyMedium" color="tertiary">
            {t('pages:signin.dont_have_an_account')}{' '}
            <Link
              href={APP_ROUTE.signup.index(sso)}
              size="medium"
              suffixIcon="chevronRight"
            >
              {t('pages:signin.signup')}
            </Link>
          </Typography>
        </Box>
        <SocialConnection authenticationFlow="login" />
        <Box gap="s3" alignItems="center">
          <Separator variant="horizontal" flex={1} />
          <Typography variant="labelTag" color="tertiary">
            {t('pages:signin.continue_with_email')}
          </Typography>
          <Separator variant="horizontal" flex={1} />
        </Box>
        <TextField
          data-testid="input-email"
          type="email"
          label={t('pages:signup.email_field_label')}
          {...form.register('email', {
            required: t('pages:signin.email_required'),
          })}
        />
        <PasswordTextField
          data-testid="input-password"
          label={t('pages:signup.password_field_label')}
          {...form.register('password', {
            required: t('pages:signin.password_required'),
          })}
        />
        {signInError ? (
          <ErrorMessage
            message={
              (
                <ClerkError
                  signInError={signInError as ClerkAPIResponseError}
                />
              ) ?? t('pages:signin.error')
            }
          />
        ) : null}
        <Box marginTop="s6">
          <Button
            size="large"
            data-testid="button-login"
            disabled={!(form.watch('email') && form.watch('password'))}
            isLoading={form.formState.isSubmitting}
            style={{ flex: 1 }}
          >
            {t('pages:signin.login')}
          </Button>
        </Box>
        <Box justifyContent="center">
          <Link href={APP_ROUTE.forgot_password}>
            {t('pages:signin.forgot_password')}
          </Link>
        </Box>
      </Box>
    </Form>
  );
};

export const getServerSideProps = getServerSidePropsFactory();

export default Signin;
