import { theme } from '../../styles/theme.css';

import { MultiLineData, MultiPoint, Point } from './LineChart';
import { getGradientOffset } from './util';

export type RenderPerformanceDefsProps = {
  baseValue: number | undefined;
  data: Point[] | MultiPoint[];
  gradientID: string;
  lineID: string;
};

/**
 * Recharts doesn't seem to allow us to convert this to a component,
 * so we keep a render function instead.
 */
export const renderPerformanceDefs = ({
  data,
  gradientID,
  lineID,
  baseValue = 0,
}: RenderPerformanceDefsProps) => {
  const gradientOffset = getGradientOffset(data, baseValue);
  const allGraphValuesArePositive = !data.some((datum) =>
    datum.value ? Number(datum.value) < baseValue : false
  );
  const allGraphValuesAreNegative = !data.some((datum) =>
    datum.value ? Number(datum.value) >= baseValue : false
  );

  const fillGradient = (
    <linearGradient id={gradientID} x1="0" y1="0" x2="0" y2="1">
      {allGraphValuesAreNegative ? null : (
        <>
          <stop
            offset={0}
            stopColor={theme.color.text.success}
            stopOpacity={0.3}
          />
          <stop
            offset={gradientOffset}
            stopColor={theme.color.text.success}
            stopOpacity={0}
          />
        </>
      )}
      {allGraphValuesArePositive ? null : (
        <>
          <stop
            offset={gradientOffset}
            stopColor={theme.color.text.error}
            stopOpacity={0}
          />
          <stop
            offset={1}
            stopColor={theme.color.text.error}
            stopOpacity={0.3}
          />
        </>
      )}
    </linearGradient>
  );

  const strokeGradient = (
    <linearGradient id={lineID} x1="0" y1="0" x2="0" y2="1">
      {allGraphValuesAreNegative ? null : (
        <stop
          offset={gradientOffset}
          stopColor={theme.color.text.success}
          stopOpacity={1}
        />
      )}
      {allGraphValuesArePositive ? null : (
        <stop
          offset={gradientOffset}
          stopColor={theme.color.text.error}
          stopOpacity={1}
        />
      )}
    </linearGradient>
  );

  return (
    <defs>
      {fillGradient}
      {strokeGradient}
    </defs>
  );
};

export const renderDefaultDefs = (gradientID: string) => {
  return (
    <defs>
      <linearGradient id={gradientID} x1="0" y1="0" x2="0" y2="1">
        <stop
          offset={0}
          stopColor={theme.color.action.default}
          stopOpacity={0.3}
        />
        <stop
          offset={1}
          stopColor={theme.color.action.default}
          stopOpacity={0}
        />
      </linearGradient>
    </defs>
  );
};

export const renderMultilineDefs = (
  rawData: MultiLineData[],
  gradientID: string
) => {
  return (
    <defs>
      {rawData.map(({ dataKey, color }, index) => (
        <linearGradient
          key={`${dataKey}_${index}_gradient`}
          id={`${gradientID}_${index}`}
          x1="0"
          y1="0"
          x2="0"
          y2="1"
        >
          <stop offset={0} stopColor={color} stopOpacity={0.3} />
          <stop offset={1} stopColor={color} stopOpacity={0} />
        </linearGradient>
      ))}
    </defs>
  );
};
