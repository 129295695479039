import { XAxis, XAxisProps, YAxis, YAxisProps } from 'recharts';

import { theme } from '../../styles/theme.css';

const TICK = {
  fill: theme.color.text.tertiary,
  fontSize: theme.fontSize.body.xsmall,
  fontWeight: theme.fontWeight.small,
};

export type ChartXAxisProps = Omit<XAxisProps, 'tickLine' | 'tick'>;

export const renderChartXAxis = ({
  axisLine = false,
  ...props
}: ChartXAxisProps) => {
  return <XAxis axisLine={axisLine} tickLine={false} tick={TICK} {...props} />;
};

export type ChartYAxisProps = Omit<
  YAxisProps,
  'axisLine' | 'tickLine' | 'tick'
>;

export const renderChartYAxis = (props: ChartYAxisProps) => {
  return <YAxis axisLine={false} tickLine={false} tick={TICK} {...props} />;
};
