import * as RadixPopover from '@radix-ui/react-popover';
import { MouseEvent, useState, useEffect } from 'react';
import { formatDate, isValidDate } from 'utils';

import { useUIState } from '../../providers/UIProvider';
import { Box } from '../Box';
import { Button } from '../Button';
import { IconButton } from '../IconButton';
import { TextField, TextFieldProps } from '../TextField';
import { Typography } from '../Typography';

import { monthPickerStyle } from './MonthPicker.css';

export type MonthPickerProps = {
  onChange: (value: Date | string) => Promise<void> | void;
  defaultYear?: number;
  value?: Date | string | null;
} & Pick<TextFieldProps, 'label' | 'name' | 'error' | 'disabled' | 'tooltip'>;

export const MonthPicker = ({
  value,
  onChange,
  disabled,
  defaultYear,
  ...props
}: MonthPickerProps) => {
  const { locale } = useUIState();
  const [localValue, setLocaleValue] = useState('');
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [selectedYear, setSelectedYear] = useState(
    defaultYear ?? new Date().getFullYear()
  );
  const selectedMonth = value ? new Date(value).getMonth() + 1 : undefined;
  const onUpdateDate = (value: string, resetIfInvalid?: boolean) => {
    // Pattern to check if date is in format MM/YYYY with month from (01 to 12)
    const patternFull = /^(0[1-9]|1[0-2])\/(\d{4})$/;
    // Pattern to check if date is in format M/YYYY to support short month (1 - 9)
    const patternShortMonth = /^([1-9])\/(\d{4})$/;

    if (patternFull.test(value) || patternShortMonth.test(value)) {
      const splittedValue = value.split('/');

      onChange(new Date(`${splittedValue[0]}/01/${splittedValue[1]}`));
    } else if (resetIfInvalid || !value) {
      // If the pattern is not respected, we reset the value - local and distant
      onChange('');
      setLocaleValue('');
    }
  };

  useEffect(() => {
    if (defaultYear && !value) {
      setSelectedYear(defaultYear);
    }
  }, [value, defaultYear]);

  useEffect(() => {
    if (!!value) {
      const safeDate =
        value && isValidDate(value) ? new Date(value) : undefined;

      setLocaleValue(
        safeDate
          ? formatDate(new Date(safeDate), locale, {
              month: '2-digit',
              year: 'numeric',
            })
          : ''
      );

      setSelectedYear(
        (safeDate ? new Date(safeDate) : new Date()).getFullYear()
      );
    }
  }, [value, locale]);

  return (
    <TextField
      value={localValue}
      type="text"
      onChange={async (event) => {
        const value = event.target.value;
        // If not present, we add a '/' to pre-format the date
        const safeValue =
          value.length === 3 && !value.includes('/')
            ? [value.slice(0, 2), value.slice(2)].join('/')
            : event.target.value;

        await setLocaleValue(safeValue);

        onUpdateDate(safeValue);
      }}
      onBlur={async (event) => onUpdateDate(event.target.value, true)}
      placeholder="MM/YYYY"
      disabled={disabled}
      suffix={
        <RadixPopover.Root
          modal
          open={isPopoverOpen}
          onOpenChange={setIsPopoverOpen}
        >
          <Box
            onClick={(event: MouseEvent<HTMLDivElement>) => {
              event.preventDefault();
              event.stopPropagation();
            }}
          >
            <RadixPopover.Trigger asChild>
              <IconButton
                variant="quaternary"
                icon="calendar"
                disabled={disabled}
              />
            </RadixPopover.Trigger>
          </Box>
          <RadixPopover.Portal>
            <RadixPopover.Content
              side="bottom"
              align="end"
              avoidCollisions
              className={monthPickerStyle.popover}
              onCloseAutoFocus={(event) => event.preventDefault()}
            >
              <Box flexDirection="column" gap="s5">
                <Box justifyContent="space-between" alignItems="center">
                  <IconButton
                    variant="secondary"
                    icon="chevronLeft"
                    onClick={() => setSelectedYear((prev) => prev - 1)}
                  />
                  <Typography variant="labelCaption">{selectedYear}</Typography>
                  <IconButton
                    variant="secondary"
                    icon="chevronRight"
                    onClick={() => setSelectedYear((prev) => prev + 1)}
                  />
                </Box>
                <Box
                  display="grid"
                  gridTemplateColumns="repeat(3, minmax(0, 1fr))"
                >
                  {[...Array(12)].map((_, i) => {
                    const monthNumber = i + 1;

                    return (
                      <Button
                        variant="quaternary"
                        key={`month-${i}`}
                        onClick={() => {
                          onChange(
                            new Date(`${monthNumber}/01/${selectedYear}`)
                          );
                          setIsPopoverOpen(false);
                        }}
                      >
                        <Typography
                          variant="labelCaption"
                          color={
                            monthNumber === selectedMonth
                              ? 'accentPrimary'
                              : undefined
                          }
                        >
                          {formatDate(
                            new Date(`${monthNumber}/01/2024`),
                            locale,
                            {
                              month: 'short',
                            }
                          ).toUpperCase()}
                        </Typography>
                      </Button>
                    );
                  })}
                </Box>
              </Box>
            </RadixPopover.Content>
          </RadixPopover.Portal>
        </RadixPopover.Root>
      }
      {...props}
    />
  );
};
