import * as React from 'react';
import { Area, AreaChart } from 'recharts';
import { alignToZero } from 'utils/data/align';

import type { ColorsBorder } from '../../styles/theme.css';
import { theme } from '../../styles/theme.css';

export type SparklineEntry = { date: number; value: number };

export type SparklineProps = {
  data: SparklineEntry[];
  color?: ColorsBorder;
  height?: number;
  isAnimationActive?: boolean;
  width?: number;
};

export const Sparkline = ({
  data,
  color,
  height = 44,
  width = 80,
  isAnimationActive,
}: SparklineProps) => {
  // In order for the gradient to always be drawn below the curve,
  // and to always get some padding below the curve, we shift the data
  // to positive numbers above the desired padding
  const positiveDataWithPadding = alignToZero(
    data,
    (entry) => entry.value,
    (entry, value) => ({ ...entry, value }),
    0.3
  );

  const lineColor = color
    ? theme.color.border[color]
    : theme.color.border.primary;

  const gradientID = React.useId(); // in order to have a unique gradient by chart, <defs> ID is share across the app.

  return data.length ? (
    <AreaChart
      data={positiveDataWithPadding}
      height={height}
      width={width}
      margin={{ top: 0, bottom: 0, left: 0, right: 0 }}
    >
      <Area
        isAnimationActive={isAnimationActive}
        type="linear"
        dataKey="value"
        fill={`url(#${gradientID})`}
        stroke={lineColor}
        strokeLinecap="butt"
        strokeLinejoin="round"
      />

      <defs>
        <linearGradient id={gradientID} x1="0" y1="0" x2="0" y2="1">
          <stop offset="50%" stopColor={lineColor} stopOpacity={0.15} />
          <stop offset="100%" stopColor={lineColor} stopOpacity={0} />
        </linearGradient>
      </defs>
    </AreaChart>
  ) : null;
};
