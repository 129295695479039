import * as React from 'react';
import {
  AreaChart,
  AreaProps,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxisProps,
  YAxisProps,
} from 'recharts';
import { BaseAxisProps } from 'recharts/types/util/types';
import { DateRange } from 'utils';
import {
  formatChartDate,
  formatDate,
  getDateFormatBasedOnPeriod,
} from 'utils/date';

import { DEFAULT_LINE_CHART_HEIGHT } from '../../constants/charts';
import { useFormatCurrencyYAxis } from '../../hooks/useFormatCurrencyYAxis';
import { theme } from '../../styles/theme.css';
import { Box } from '../Box';

import { LineChartTooltip } from './LineChartTooltip';
import { renderAreaChartAreas } from './areas';
import {
  renderDefaultDefs,
  renderMultilineDefs,
  renderPerformanceDefs,
} from './defs';
import { renderGridWithAxis } from './grid';
import { formatMultilineData, getDataPointTotalValue } from './util';

export type LineChartType = 'value' | 'performance';

export type Point = {
  date: number;
  value: number;
  label?: string;
};

export type MultiPoint = { date: number } & Record<
  string,
  string | number | undefined
>;

export type LineData = {
  points: Point[];
  color?: string;
  lineType?: AreaProps['type'];
};

export type MultiLineData = LineData & {
  dataKey: string;
};

export type LabelledValue = {
  label: string;
  value: number;
};

export type BaseLineChartProps = {
  locale: string;
  periodDisplayMode: DateRange;
  baseValue?: number;
  formatXAxis?: BaseAxisProps['tickFormatter'];
  formatYAxis?: BaseAxisProps['tickFormatter'];
  height?: number;
  onHover?: (props?: LabelledValue) => void;
  tooltipDigit?: number | 'auto';
  xTicks?: XAxisProps['ticks'];
  yTicks?: YAxisProps['ticks'];
};

export type SingleLineChartProps = BaseLineChartProps & {
  data: LineData;
  type?: LineChartType;
};

export type MultiLineChartProps = BaseLineChartProps & {
  data: MultiLineData[];
  type?: 'value';
};

export type LineChartProps = SingleLineChartProps | MultiLineChartProps;

export const LineChart = ({
  data: rawData,
  type = 'value',
  periodDisplayMode,
  locale,
  onHover,
  formatXAxis,
  formatYAxis,
  height = DEFAULT_LINE_CHART_HEIGHT,
  xTicks,
  yTicks,
  baseValue,
  tooltipDigit,
}: LineChartProps) => {
  const { formatCurrencyYAxis } = useFormatCurrencyYAxis();
  const gradientID = React.useId(); // in order to have a unique gradient by chart, <defs> ID is share across the app.
  const lineID = React.useId(); // in order to have a unique gradient by chart, <defs> ID is share across the app.
  const [activeIndex, setActiveIndex] = React.useState(-1);

  const isPerformance = type === 'performance';

  const isMultiline = Array.isArray(rawData);
  const data: Point[] | MultiPoint[] = React.useMemo(
    () => (isMultiline ? formatMultilineData(rawData) : rawData.points),
    [isMultiline, rawData]
  );

  const resetActive = () => {
    setActiveIndex(-1);
    onHover?.();
  };

  const setActiveDataPointTooltip = (
    dataPoint: Point | MultiPoint,
    activeTooltipIndex: number
  ) => {
    const newValue = getDataPointTotalValue(dataPoint);

    setActiveIndex(activeTooltipIndex);
    onHover?.({
      label: formatDate(
        new Date(dataPoint.date),
        locale,
        getDateFormatBasedOnPeriod(periodDisplayMode)
      ),
      value: newValue,
    });
  };

  const handleGraphHover = (activeTooltipIndex?: number) => {
    const hasUndefinedData =
      activeTooltipIndex === undefined || data.length === 0;

    if (hasUndefinedData || !data[activeTooltipIndex]) {
      resetActive();
      return;
    }

    const dataPoint = data[activeTooltipIndex];

    if (!dataPoint) {
      resetActive();
      return;
    }

    setActiveDataPointTooltip(dataPoint, activeTooltipIndex);
  };

  const renderTooltipContent = React.useCallback(
    ({ active, payload, label }: TooltipProps<number, string>) => (
      <LineChartTooltip
        periodDisplayMode={periodDisplayMode}
        isMultiline={isMultiline}
        locale={locale}
        active={active}
        payload={payload}
        label={label}
        digit={tooltipDigit}
      />
    ),
    [isMultiline, locale, periodDisplayMode, tooltipDigit]
  );

  const tooltipStrokeColor = () => {
    if (isMultiline) {
      return theme.color.border.primary;
    }

    if (isPerformance) {
      const value = (data as Point[])[activeIndex]?.value as number;

      return value >= (baseValue ?? 0)
        ? theme.color.text.success
        : theme.color.text.error;
    }

    return theme.color.action.default;
  };

  const tooltip = (
    <Tooltip
      content={renderTooltipContent}
      wrapperStyle={{ outline: 'none', zIndex: 50, maxWidth: 300 }}
      cursor={{
        stroke: tooltipStrokeColor(),
        strokeOpacity: 0.4,
        strokeWidth: 1,
        strokeDasharray: '2 4',
      }}
    />
  );

  const areaChartDefs = React.useMemo(() => {
    if (isPerformance) {
      return renderPerformanceDefs({
        data,
        gradientID,
        lineID,
        baseValue,
      });
    }

    if (isMultiline) {
      return renderMultilineDefs(rawData, gradientID);
    }

    return renderDefaultDefs(gradientID);
  }, [
    rawData,
    isMultiline,
    data,
    isPerformance,
    baseValue,
    gradientID,
    lineID,
  ]);

  const formatChartXAxisValue = React.useCallback(
    (tickValue: string) =>
      formatChartDate(new Date(tickValue), locale, periodDisplayMode),
    [locale, periodDisplayMode]
  );

  return (
    <Box style={{ position: 'relative', width: '100%', height }}>
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
        }}
      >
        {/* width="99%" for responsive, related to this : https://github.com/recharts/recharts/issues/172 */}
        <ResponsiveContainer height={height} width="99%">
          <AreaChart
            data={data}
            onMouseMove={(nextState) =>
              nextState ? handleGraphHover(nextState.activeTooltipIndex) : null
            }
            onMouseLeave={() => handleGraphHover()}
          >
            {areaChartDefs}
            {renderGridWithAxis(
              isMultiline,
              data,
              formatXAxis ?? formatChartXAxisValue,
              formatYAxis ?? formatCurrencyYAxis,
              xTicks,
              yTicks
            )}
            {renderAreaChartAreas({
              rawData,
              gradientID,
              isPerformance,
              baseValue,
              lineID,
            })}
            {tooltip}
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </Box>
  );
};
