/**
 * Returns a css transition string for the given css properties
 * @param properties - the css properties
 * @param duration - the css duration
 * @param timing - the timing function
 * @param delay - the css delay
 */
export const createTransition = (
  properties: React.CSSProperties['transitionProperty'][],
  duration: React.CSSProperties['transitionDuration'],
  timing: React.CSSProperties['transitionTimingFunction'],
  delay?: React.CSSProperties['transitionDelay']
) => {
  return properties
    .map(
      (property) =>
        `${property} ${duration} ${timing}${delay ? ` ${delay}` : ''}`
    )
    .join(', ');
};
