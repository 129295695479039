import { theme } from '../../styles/theme.css';

import { MultiPoint } from './LineChart';

export type DotProps = {
  cx: number;
  cy: number;
  dataKey?: string;
  fill?: string;
  payload?: MultiPoint;
};

export const Dot = ({ cx, cy, fill, dataKey, payload }: DotProps) => {
  if (!dataKey || !payload || !payload?.[dataKey]) {
    return null;
  }

  return (
    <circle
      r={3}
      cx={cx}
      cy={cy}
      fill={fill ?? theme.color.action.default}
      stroke={theme.color.surface.disabled}
      strokeWidth={12}
    />
  );
};
