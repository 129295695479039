import * as React from 'react';
import { capitalize } from 'utils';

import { Box, BoxProps } from '../Box';
import { NumberFormat } from '../NumberFormat';
import { Typography } from '../Typography';

import {
  PieChart,
  MultiLevelChartLoader,
  MultiLevelChartLoaderPieConfig,
  PieChartProps,
  PieData,
} from './PieChart';

export type AssetPieChartData = PieData<{
  amount: number;
  redirectRoute?: string;
}>;

export type AssetPieChartProps = {
  data: AssetPieChartData[];
  size: number;
  thickness: number;
  animate?: boolean;
  hideLegend?: boolean;
  inert?: boolean;
  onAnimationEnd?: PieChartProps['onAnimationEnd'];
  onPieClick?: (data?: AssetPieChartData) => void;
  preventSort?: boolean;
} & Omit<BoxProps<'div'>, 'onAnimationEnd'>;

export const AssetPieChart = React.forwardRef<
  HTMLDivElement,
  AssetPieChartProps
>(
  (
    {
      data,
      animate,
      onAnimationEnd,
      hideLegend,
      inert,
      size,
      thickness,
      preventSort,
      onPieClick,
    },
    ref
  ) => {
    const total = data.length
      ? Math.round(data.reduce((acc, item) => acc + item.amount, 0))
      : 0;

    const sortedData = React.useMemo(() => {
      return (preventSort ? data : data.sort((a, b) => b.share - a.share)).map(
        (datum) => ({
          ...datum,
          name: datum.name && capitalize(datum.name),
        })
      );
    }, [data, preventSort]);

    return (
      <div
        style={{ width: size, minWidth: size, minHeight: size, height: size }}
        ref={ref}
      >
        <PieChart<{ amount: number }>
          animate={animate}
          onPieClick={
            onPieClick
              ? (infos) => onPieClick(sortedData[infos?.index ?? 0])
              : undefined
          }
          onAnimationEnd={onAnimationEnd}
          data={[{ data: sortedData, size, thickness }]}
          inert={inert}
          renderActiveInfos={(active) => {
            if (hideLegend) {
              return null;
            }
            if (!active) {
              return <Total total={total} />;
            }
            const { index } = active;

            return <CustomLegend data={data[index]} />;
          }}
        />
      </div>
    );
  }
);

AssetPieChart.displayName = 'AssetPieChart';

const Total = ({ total }: { total: number }) => {
  if (!total) {
    return null;
  }
  return (
    <Box flexDirection="column" alignItems="center">
      <NumberFormat value={total} type="currency" textVariant="headingLarge" />
      <Typography variant="bodyMedium" color="tertiary">
        Total
      </Typography>
    </Box>
  );
};

const CustomLegend = ({ data }: { data: AssetPieChartData | undefined }) => {
  if (!data) {
    return null;
  }
  return (
    <Box flexDirection="column" alignItems="center">
      <NumberFormat
        value={data.amount}
        type="currency"
        textVariant="headingLarge"
      />
      <Box justifyContent="center" gap="s2">
        <Typography
          variant="bodyMedium"
          color="tertiary"
          lineClamp={1}
          wordBreak="break-all"
        >
          {data.name}
        </Typography>
        <Typography variant="bodyMedium" color="tertiary">
          •
        </Typography>
        <NumberFormat
          digit={data.share < 1 ? 2 : 0}
          value={data.share}
          type="percent"
          textVariant="bodyMedium"
          textColor="tertiary"
          notSecret
        />
      </Box>
    </Box>
  );
};

export type AssetPieChartLoaderProps = MultiLevelChartLoaderPieConfig;

export const AssetPieChartLoader = ({
  size,
  thickness,
}: AssetPieChartLoaderProps) => {
  return <MultiLevelChartLoader config={[{ size, thickness }]} />;
};
