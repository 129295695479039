import { MultiLineData, MultiPoint, Point } from './LineChart';

export const VALUE_PREFIX = 'value';

export const LABEL_PREFIX = 'label';

/**
 * Returns a unique dataKey for multi line charts entries
 * don't forget that the dataKey is not necessarily unique
 */
export const getMultiDataKey = (
  dataKey: string,
  dataIndex: number,
  prefix: string
) => {
  return `${prefix}_${dataKey}_${dataIndex}`;
};

/**
 * Returns a unique data key for multi line charts values
 */
export const getMultiValueDataKey = (dataKey: string, dataIndex: number) => {
  return getMultiDataKey(dataKey, dataIndex, VALUE_PREFIX);
};

/**
 * Returns a unique data key for multi line charts labels
 */
export const getMultiLabelDataKey = (dataKey: string, dataIndex: number) => {
  return getMultiDataKey(dataKey, dataIndex, LABEL_PREFIX);
};

/**
 * Extract the original data index from a given dataKey
 */
export const getDataKeyIndex = (dataKey: string) =>
  Number(dataKey.replace(/^.*(\d+)$/, '$1'));

/**
 * Format the given MultiLineData[] to MultiPoint[]
 */
export const formatMultilineData = (rawData: MultiLineData[]) => {
  if (rawData.length === 0) {
    return [];
  }

  // Get all the unique dates and sort them
  const dates = new Set(
    rawData.flatMap((data) => data.points.map((datum) => datum.date))
  );
  const newPoints: MultiPoint[] = Array.from(dates)
    .sort()
    .map((date) => ({
      date,
    }));

  // Populate with data for each entry
  rawData.forEach(({ points, dataKey }, dataIndex) => {
    points.forEach(({ date, value, label }, index) => {
      const pointIndex = newPoints.findIndex(
        ({ date: newDataDate }) => newDataDate === date
      );

      if (pointIndex !== -1) {
        const currentPoint = newPoints[pointIndex] as MultiPoint;
        currentPoint[getMultiValueDataKey(dataKey, dataIndex)] =
          Math.round(value);
        currentPoint[getMultiLabelDataKey(dataKey, dataIndex)] = label;
        // Put the point before the first available one to 0 value
        if (index === 0) {
          const previousPoint = newPoints[pointIndex - 1] as MultiPoint;
          if (previousPoint) {
            previousPoint[getMultiValueDataKey(dataKey, dataIndex)] = 0;
            previousPoint[getMultiLabelDataKey(dataKey, dataIndex)] = label;
          }
        }
      }
    });
  });

  return newPoints;
};

/**
 * Get the gradient offset for performance chart (the ratio at which the graph
 * transitions from positive values to negative values)
 */
export const getGradientOffset = (
  data: Point[] | MultiPoint[],
  performanceIndexValue: number
) => {
  const dataMax = Math.max(
    ...data.map((datum) => (datum.value ? Number(datum.value) : 0))
  );

  if (dataMax <= performanceIndexValue) {
    return 0;
  }

  const dataMin = Math.min(
    ...data.map((datum) => (datum.value ? Number(datum.value) : 0))
  );

  if (dataMin >= performanceIndexValue) {
    return 1;
  }
  return (dataMax - performanceIndexValue) / (dataMax - dataMin);
};

/**
 * Type guard to discriminate between a Point and MultiPoint
 */
const isPoint = (dataPoint: Point | MultiPoint): dataPoint is Point => {
  return !!dataPoint.value;
};

/**
 * Returns the total value for a given MultiPoint
 */
const getMultipointTotalValue = (dataPoint: MultiPoint) => {
  return Object.keys(dataPoint).reduce(
    (previousValue, currentValue) =>
      currentValue.startsWith(VALUE_PREFIX)
        ? previousValue + Number(dataPoint[currentValue])
        : previousValue,
    0
  );
};

/**
 * Returns the total value for a given dataPoint (Point or MultiPoint)
 */
export const getDataPointTotalValue = (dataPoint: Point | MultiPoint) => {
  if (isPoint(dataPoint)) {
    return Number(dataPoint.value);
  } else {
    return getMultipointTotalValue(dataPoint);
  }
};
